import React, {useEffect, useState} from "react";
import {ArrowLeftOnRectangleIcon, Cog6ToothIcon, CreditCardIcon, FolderIcon} from "@heroicons/react/24/outline";
import {useIntl} from "react-intl";
import router from "../../../../routing/Router";
// @ts-ignore
import logo from "../../../../assets/img/icon.svg";
import {DashboardFrame, PrimaryNavigationItemProps, SecondaryNavigationItemProps, useAuth} from "peggirkit";

type Props = {
    title: string,
    children: JSX.Element[]
};

const SerpotrackFrame = ({title, children}: Props) => {
    const intl = useIntl();
    const [isSigningOut, setIsSigningOut] = useState(false);
    const authContext = useAuth();

    useEffect(() => {
        if (!isSigningOut) {
            return;
        }

        authContext?.onSignOut().catch(() => {
            setIsSigningOut(false);
        });
    }, [isSigningOut]);

    const navigation: PrimaryNavigationItemProps[] = [
        {
            name: intl.formatMessage({id: "projects"}),
            href: router.projects.absolutePath(),
            icon: FolderIcon,
            current: false
        },
        {
            name: intl.formatMessage({id: "account"}),
            href: router.account.absolutePath(),
            icon: Cog6ToothIcon,
            current: false
        },
        {
            name: intl.formatMessage({id: "subscription"}),
            href: router.subscription.absolutePath(),
            icon: CreditCardIcon,
            current: false
        },
        {
            name: intl.formatMessage({id: "signOut"}),
            onClick: () => setIsSigningOut(true),
            icon: ArrowLeftOnRectangleIcon,
            current: false
        },
    ];

    const secondaryNavigation: SecondaryNavigationItemProps[] = [
        {
            name: intl.formatMessage({id: "help"}),
            href: "https://serpotrack.com/help"
        },
        {
            name: intl.formatMessage({id: "blog"}),
            href: "https://serpotrack.com/blog"
        },
        {
            name: intl.formatMessage({id: "contact"}),
            href: "https://serpotrack.com/contact"
        },
        {
            name: intl.formatMessage({id: "termsConditions"}),
            href: "https://serpotrack.com/terms-and-conditions"
        },
        {
            name: intl.formatMessage({id: "privacyPolicy"}),
            href: "https://serpotrack.com/privacy-policy"
        },
    ];

    return (
        <DashboardFrame
            appName={"Serpotrack"}
            title={title}
            logo={logo}
            primaryNavigation={navigation}
            secondaryNavigation={secondaryNavigation}
            secondaryNavigationTitle={intl.formatMessage({id: "external"})}
        >
            {children}
        </DashboardFrame>
    );
};

export default SerpotrackFrame;