import React, {useEffect, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {AlertModal, AlertText, NotificationService} from "peggirkit";
import {User} from "serpotrack-connector";
import {removeMfaTotp} from "serpotrack-connector/dist/Serpotrack";

type Props = {
    show: boolean,
    setShow: (show: boolean) => void,
    user: User,
    setUser: (user: User) => void
};

const Disable2FaModal = ({show, setShow, user, setUser}: Props) => {
    const intl = useIntl();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!loading) {
            return;
        }

        removeMfaTotp()
            .then(() => {
                setLoading(false);
                setUser({...user, mfaTotpEnabled: false});
                setShow(false);
                NotificationService.notify({type: "success", text: intl.formatMessage({id: "2FaDisabled"})});
            })
            .catch(() => {
                setLoading(false);
                setShow(false);
                NotificationService.notify({type: "danger", text: intl.formatMessage({id: "unexpectedServerError"})});
            });
    }, [loading]);

    return (
        <AlertModal
            type="danger"
            show={show}
            hideIcon={false}
            onClose={() => setShow(false)}
            onContinue={() => setLoading(true)}
            title={intl.formatMessage({id: "disable2Fa"})}
            cancelText={intl.formatMessage({id: "cancel"})}
            continueText={intl.formatMessage({id: "confirmDisable"})}
            loading={loading}
        >
            <AlertText>
                <FormattedMessage id={"confirmDisable2Fa"}/>
            </AlertText>
        </AlertModal>
    );
};

export default Disable2FaModal;