import React, {useEffect, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {useNavigate} from "react-router-dom";
import router from "../../../routing/Router";
import {AlertModal, AlertText} from "peggirkit";
import {deleteProject} from "serpotrack-connector";

type Props = {
    show: boolean,
    setShow: (show: boolean) => void,
    projectId: number
};

const DeleteProjectModal = ({show, setShow, projectId}: Props) => {
    const intl = useIntl();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!loading) {
            return;
        }

        deleteProject(projectId).then(() => {
            navigate(router.projects.absolutePath());
        });
    }, [loading]);

    return (
        <AlertModal
            type={"danger"}
            loading={loading}
            show={show}
            onClose={() => setShow(false)}
            onContinue={() => setLoading(true)}
            title={intl.formatMessage({id: "confirmDeleteProject"})}
            cancelText={intl.formatMessage({id: "cancel"})}
            continueText={intl.formatMessage({id: "delete"})}
        >
            <AlertText>
                <FormattedMessage id="confirmDeleteProject.message"/>
            </AlertText>
        </AlertModal>
    );
};

export default DeleteProjectModal;