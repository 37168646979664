import React, {useEffect, useState} from "react";
import {useIntl} from "react-intl";
import router, {breadcrumbItemProjects} from "../../../routing/Router";
import {PlusIcon} from "@heroicons/react/24/outline";
import ProjectsDataTable from "../../widgets/projects/ProjectsDataTable";
import {useNavigate} from "react-router-dom";
import AccountNotConfirmedAlert from "../../widgets/AccountNotConfirmedAlert";
import {BreadcrumbsContent, PageHeading, useAuth} from "peggirkit";
import SerpotrackFrame from "./layout/SerpotrackFrame";
import {User} from "serpotrack-connector";
import NoPlanAlert from "../../widgets/NoPlanAlert";

const Projects = () => {
    const intl = useIntl();
    const navigate = useNavigate();
    const auth = useAuth();
    const [user, setUser] = useState<User | null>(null);
    const breadcrumbs: BreadcrumbsContent = {
        backText: intl.formatMessage({id: "back"}),
        items: [breadcrumbItemProjects]
    };

    useEffect(() => {
        auth?.currentUser().then((user: User) => setUser(user));
    }, []);

    return (
        <SerpotrackFrame title={intl.formatMessage({id: "projects"})}>
            <PageHeading
                breadcrumbsContent={breadcrumbs}
                title={intl.formatMessage({id: "projects"})}
                actions={{
                    moreButtonText: intl.formatMessage({id: "more"}),
                    actions: [
                        {
                            actionType: "button",
                            text: intl.formatMessage({id: "newProject"}),
                            primary: true,
                            icon: PlusIcon,
                            onClick: () => navigate(router.newProject.absolutePath()),
                        }
                    ]
                }}
            />

            <AccountNotConfirmedAlert user={user}/>
            <NoPlanAlert user={user}/>
            <ProjectsDataTable/>
        </SerpotrackFrame>
    );
};

export default Projects;