import React from "react";
import {useIntl} from "react-intl";
import {getHistoryFromDate} from "../HistoryLimit";
import {DayPicker} from "peggirkit";
import {User} from "serpotrack-connector";

type Props = {
    show: boolean,
    setShow: (show: boolean) => void,
    date: Date,
    setDate: (date: Date) => void,
    user: User | null
};

const KeywordQueryDayPicker = ({show, setShow, date, setDate, user}: Props) => {
    const intl = useIntl();
    const historyLimit: number | null = (user !== null && user.subscriptions.length > 0)
        ? user.subscriptions[0].plan.historyLimit
        : null;

    return (
        <DayPicker
            title={intl.formatMessage({id: "pickADay"})}
            cancelText={intl.formatMessage({id: "cancel"})}
            continueText={intl.formatMessage({id: "apply"})}
            show={show}
            setShow={setShow}
            defaultSelected={date}
            onSelect={(date?: Date) => {
                if (date) {
                    setDate(date);
                }
            }}
            toDate={new Date()}
            fromDate={getHistoryFromDate(historyLimit)}
        />
    );
};

export default KeywordQueryDayPicker;