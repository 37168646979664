import React from "react";
import {useIntl} from "react-intl";
import {capitalizeFirstLetter, DescriptionList, SectionHeading} from "peggirkit";
import {AccountTabProps} from "../../page/authenticated/Account";


const AccountTabGeneral = ({user}: AccountTabProps) => {
    const intl = useIntl();
    return (
        <>
            <SectionHeading
                title={intl.formatMessage({id: "general"})}
                description={intl.formatMessage({id: "accountTabGeneralDescription"})}
            />
            <DescriptionList
                items={[
                    {
                        key: intl.formatMessage({id: "emailAddressUsername"}),
                        value: user && capitalizeFirstLetter(user.username)
                    },
                    {
                        key: intl.formatMessage({id: "verified"}),
                        value: user
                            && (user?.verified ? intl.formatMessage({id: "yes"}) : intl.formatMessage({id: "no"}))
                    }
                ]}
            />
        </>
    );
};

export default AccountTabGeneral;