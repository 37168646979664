import React from "react";
import {useIntl} from "react-intl";
import {Table, TextSkeleton} from "peggirkit";

const OutrankingSerpTablePlaceholder = () => {
    const intl = useIntl();
    return (
        <Table
            hideHeader={true}
            columns={[
                {
                    name: intl.formatMessage({id: "rank"}),
                    sortable: true,
                    sorted: "asc",
                    secondary: false,
                },
                {
                    name: intl.formatMessage({id: "serpResult"}),
                    sortable: false,
                    secondary: true,
                }
            ]}
            data={[...Array(1)].map(() => ({
                [intl.formatMessage({id: "rank"})]:
                    <TextSkeleton
                        pulse={false}
                        width={"max-w-[50px]"}
                    />,
                [intl.formatMessage({id: "serpResult"})]:
                    <div className={"flex flex-col gap-2"}>
                        <TextSkeleton pulse={false} width={"max-w-[50px]"}/>
                        <TextSkeleton pulse={false} width={"max-w-[300px]"} height={"h-3"}/>
                        <TextSkeleton pulse={false} width={"max-w-[200px]"}/>
                        <TextSkeleton pulse={false} width={"max-w-[200px]"}/>
                    </div>
            }))}
        />
    );
};

export default OutrankingSerpTablePlaceholder;