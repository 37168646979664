import React from "react";
import {useIntl} from "react-intl";
import {Alert, TextSkeleton} from "peggirkit";
import {Keyword, User} from "serpotrack-connector";

type Props = {
    currentUser: User | null,
    keyword: Keyword
};

const NoDataAlert = ({currentUser, keyword}: Props) => {
    const intl = useIntl();

    if (keyword.positions.length > 0) {
        return <></>;
    }

    if (currentUser === null) {
        return <Alert
            type={"danger"}
            title={
                <div className={"w-[100px]"}>
                    <TextSkeleton width={"max-w-[100px]"} color={"bg-red-200"}/>
                </div>
            }
        />
    }

    return (
        <Alert
            type={"danger"}
            title={intl.formatMessage({id: "noData"})}
            description={
                currentUser.subscriptions.length > 0
                    ? intl.formatMessage({id: "noPositionDataWithPlanExplanation"}, {
                        hourlyInterval: currentUser.subscriptions[0].plan.keywordUpdateHourlyIntervalLimit,
                        maxPosition: currentUser.subscriptions[0].plan.keywordMaxPositionLimit
                    })
                    : intl.formatMessage({id: "noPositionDataExplanation"})
            }
        />
    );
};

export default NoDataAlert;