import React, {useEffect, useState} from "react";
import {useIntl} from "react-intl";
import {useForm} from "react-hook-form";
import {useNavigate} from "react-router-dom";
import router from "../../../../routing/Router";
import {countryCodeOptions, languageOptions} from "../../../util/CountryUtils";
import {
    FormLayoutCard,
    FormLayoutFooterOptions,
    InputCreatableSelect,
    InputField,
    RadioGroup,
    TwoColumnFormLayout
} from "peggirkit";
import {createProject, Project} from "serpotrack-connector";
import ProjectNameField from "./fields/ProjectNameField";

type FormValues = {
    name: string,
    url: string,
    device: string,
    geographicalLocation: { value: string, label: string },
    homeLocation: { value: string, label: string }
};

const NewProjectForm = () => {
    const navigate = useNavigate();
    const intl = useIntl();
    const {register, control, getValues, handleSubmit, formState: {errors}} = useForm<FormValues>();
    const [loading, setLoading] = useState(false);
    const [formError, setFormError] = useState<string | null>(null);

    useEffect(() => {
        if (!loading) {
            return;
        }

        const data = getValues();
        createProject(
            data.name,
            data.url,
            data.device,
            data.geographicalLocation.value,
            data.homeLocation.value,
        ).then((createdProject: Project): void => {
            const projectUrl: string = router.project.absolutePath(createdProject.id);
            navigate(projectUrl, {state: {project: createdProject}});
        }).catch(e => {
            setFormError(intl.formatMessage({
                id: e.response.status === 412 ? "projectsLimitReached" : "errorCreatingProject"
            }));
            setLoading(false);
        });
    }, [loading]);

    return (
        <TwoColumnFormLayout
            onSubmit={handleSubmit(() => setLoading(true))}
            error={formError}
        >
            <FormLayoutCard
                title={intl.formatMessage({id: "createProject.sectionGeneral.title"})}
                description={intl.formatMessage({id: "createProject.sectionGeneral.description"})}
            >
                <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-6 sm:col-span-4">
                        <ProjectNameField
                            fieldName={"name"}
                            register={register}
                            fieldError={errors.name}
                            disabled={loading}
                        />
                    </div>
                    <div className="col-span-6 sm:col-span-4">
                        <InputField
                            type={"text"}
                            id={"siteUrl"}
                            displayName={intl.formatMessage({id: "websiteToMonitor"})}
                            tip={intl.formatMessage({id: "siteUrl.tip"})}
                            placeholder={intl.formatMessage({id: "sitePlaceholder"})}
                            addOn={"https://"}
                            reactHookForm={{
                                ...register("url", {
                                    required: intl.formatMessage({id: "enterSiteUrl"}),
                                    minLength: {
                                        value: 3,
                                        message: intl.formatMessage({id: "mustContainAtLeastCharacters"}, {min: 3})
                                    },
                                    maxLength: {
                                        value: 256,
                                        message: intl.formatMessage({id: "canContainAtMostCharacters"}, {max: 256})
                                    }
                                })
                            }}
                            error={errors.url && errors.url.message}
                            disabled={loading}
                        />
                    </div>
                </div>
            </FormLayoutCard>

            <FormLayoutCard
                title={intl.formatMessage({id: "createProject.sectionSerp.title"})}
                description={intl.formatMessage({id: "createProject.sectionSerp.description"})}
            >
                <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-6 sm:col-span-4">
                        <InputCreatableSelect
                            id={"geographicalLocation"}
                            displayName={intl.formatMessage({id: "country"})}
                            nameHookForm="geographicalLocation"
                            options={countryCodeOptions}
                            tip={intl.formatMessage({id: "country.tip"})}
                            disabled={loading}
                            control={control}
                            rules={{required: intl.formatMessage({id: "selectCountry"})}}
                            error={errors.geographicalLocation && errors.geographicalLocation.message}
                            isValidNewOption={(val: string) => val.length >= 1 && val.length <= 20}
                            formatCreateLabel={inputValue => `${intl.formatMessage({id: "useCustomEntry"})}: ${inputValue}`}
                            placeholder={intl.formatMessage({id: "select"})}
                        />
                    </div>
                    <div className="col-span-6 sm:col-span-4">
                        <InputCreatableSelect
                            id={"homeLocation"}
                            displayName={intl.formatMessage({id: "language"})}
                            nameHookForm="homeLocation"
                            options={languageOptions}
                            tip={intl.formatMessage({id: "language.tip"})}
                            disabled={loading}
                            control={control}
                            rules={{required: intl.formatMessage({id: "selectLanguage"})}}
                            error={errors.homeLocation && errors.homeLocation.message}
                            isValidNewOption={(val: string) => val.length >= 1 && val.length <= 20}
                            formatCreateLabel={inputValue => `${intl.formatMessage({id: "useCustomEntry"})}: ${inputValue}`}
                            placeholder={intl.formatMessage({id: "select"})}
                        />
                    </div>
                </div>

                <RadioGroup
                    title={intl.formatMessage({id: "device"})}
                    tip={intl.formatMessage({id: "device.tip"})}
                    name={"device"}
                    error={errors.device && errors.device.message}
                    disabled={loading}
                    options={[
                        {value: "desktop", label: intl.formatMessage({id: "desktop"})},
                        {value: "mobile", label: intl.formatMessage({id: "mobile"})}
                    ]}
                    reactHookForm={{
                        ...register("device", {
                            required: intl.formatMessage({id: "selectDevice"})
                        })
                    }}
                />
            </FormLayoutCard>

            <FormLayoutFooterOptions
                cancelText={intl.formatMessage({id: "cancel"})}
                onCancel={() => navigate(router.projects.absolutePath())}
                submitText={intl.formatMessage({id: "create"})}
                submitLoading={loading}
                disabled={loading}
            />
        </TwoColumnFormLayout>
    );
};

export default NewProjectForm;