import React, {useState} from "react";
import {TrashIcon} from "@heroicons/react/24/outline";
import {useIntl} from "react-intl";
import DeleteKeywordsModal, {DeleteKeywordsProps} from "../DeleteKeywordsModal";
import {Button} from "peggirkit";

const DeleteSection = ({
                           projectId,
                           keywords,
                           setKeywordDetailsToShow,
                           setKeywordsOverviewLastUpdated
                       }: DeleteKeywordsProps) => {
    const intl = useIntl();
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);

    return (
        <>
            <Button
                type={"danger"}
                icon={TrashIcon}
                text={intl.formatMessage({id: "deleteKeyword"})}
                onClick={() => setShowDeleteDialog(true)}
            />
            <DeleteKeywordsModal
                projectId={projectId}
                keywords={keywords}
                show={showDeleteDialog}
                setShow={setShowDeleteDialog}
                setKeywordDetailsToShow={setKeywordDetailsToShow}
                setKeywordsOverviewLastUpdated={setKeywordsOverviewLastUpdated}
            />
        </>
    );
};

export default DeleteSection;