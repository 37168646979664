import React, {useEffect, useMemo, useState} from "react";
import {useIntl} from "react-intl";
import {ArrowTopRightOnSquareIcon} from "@heroicons/react/24/outline";
import RankingUrlsTablePlaceholder from "./RankingUrlsTablePlaceholder";
import {SectionHeading, Table, TableColumn, TableData, TableIconButton} from "peggirkit";
import {KeywordPosition} from "serpotrack-connector";

type Props = {
    positions: KeywordPosition[]
};

const RankingUrlsSection = ({positions}: Props) => {
    const intl = useIntl();
    const [data, setData] = useState<TableData[]>([]);
    const columns: TableColumn[] = useMemo(() => ([
        {
            name: intl.formatMessage({id: "url"}),
            sortable: false,
            secondary: false,
            tdClassName: "font-normal text-black"
        },
        {
            name: intl.formatMessage({id: "visit"}),
            sortable: false,
            secondary: true,
            hideLabel: true,
            alignRight: true
        },
    ]), []);

    useEffect(() => {
        const urls = [...new Set(positions.map(p => p.url))].sort();
        setData(urls.map((url: string) => ({
            [intl.formatMessage({id: "url"})]: url,
            [intl.formatMessage({id: "visit"})]: <TableIconButton
                title={intl.formatMessage({id: "openInNewTab"})}
                href={url}
                target={"_blank"}
                rel={"nofollow"}
                icon={ArrowTopRightOnSquareIcon}
            />
        })));
    }, [positions]);

    return (
        <div>
            <SectionHeading
                title={intl.formatMessage({id: "rankingUrls"})}
                description={intl.formatMessage({id: "rankingUrls.description"})}
                className={"mb-4"}
            />

            {
                positions.length > 0
                    ? <Table
                        columns={columns}
                        data={data}
                    />
                    : <RankingUrlsTablePlaceholder
                        columns={columns}
                    />
            }
        </div>
    );
};

export default RankingUrlsSection;