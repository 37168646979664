import React from "react";
import {User} from "serpotrack-connector";
import {useIntl} from "react-intl";
import {DescriptionListItem} from "peggirkit";

export const generateBackupCodesSettingsItem = (
    user: (User | undefined),
    setShowGenerateBackupCodesModal: ((show: boolean) => void),
): DescriptionListItem[] => {
    const intl = useIntl();

    if (!user || !user.mfaTotpEnabled) {
        return [];
    }

    return [{
        key: intl.formatMessage({id: "backupCodes"}),
        value: (!user || !user.mfaTotpEnabled
                ? undefined
                : intl.formatMessage({id: "generateBackupCodesDescription"})
        ),
        options: [{
            text: intl.formatMessage({id: "generate"}),
            onClick: () => setShowGenerateBackupCodesModal(true)
        }]
    }];
};