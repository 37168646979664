import React from "react";
import {PlusIcon, TableCellsIcon} from "@heroicons/react/24/outline";
import {useIntl} from "react-intl";
import {EmptyState} from "peggirkit";

type Props = {
    setShowAddKeywordsDialog: (show: boolean) => void
};

const KeywordsDataTableEmptyState = ({setShowAddKeywordsDialog}: Props) => {
    const intl = useIntl();
    return (
        <EmptyState
            className={"border-t"}
            icon={TableCellsIcon}
            title={intl.formatMessage({id: "noKeywords"})}
            description={intl.formatMessage({id: "noKeywordsDescriptionGetStarted"})}
            action={{
                icon: PlusIcon,
                text: intl.formatMessage({id: "addKeywords"}),
                onClick: () => setShowAddKeywordsDialog(true)
            }}
        />
    );
};

export default KeywordsDataTableEmptyState;