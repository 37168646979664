import React from "react";
import AuthFormFooterRegister from "../../widgets/auth/AuthFormFooterRegister";
// @ts-ignore
import logo from "../../../assets/img/icon.svg";
import {ResetPassword as ResetPasswordPage} from "serpotrack-frontend-authentication";
import router from "../../../routing/Router";

const ResetPassword = () => {
    return (
        <ResetPasswordPage
            logo={logo}
            onSignedInRedirectTo={router.projects.absolutePath()}
            footer={<AuthFormFooterRegister/>}
        />
    );
};

export default ResetPassword;