import React, {useMemo, useState} from "react";
import {useIntl} from "react-intl";
import KeywordsDataTablePositionFormatter from "./KeywordsDataTablePositionFormatter";
import PaginationSummaryFormatter from "../PaginationSummaryFormatter";
import KeywordsDataTableEmptyState from "./KeywordsDataTableEmptyState";
import {SetKeywordDetailsToShow} from "../../page/authenticated/Project";
import {DateRange} from "react-day-picker";
import {ArrowDownTrayIcon, CurrencyDollarIcon, TrashIcon} from "@heroicons/react/24/outline";
import DeleteKeywordsModal from "./DeleteKeywordsModal";
import KeywordsToCsvModal, {ExportToCsvOptions} from "./KeywordsToCsvModal";
import {DataTable, DataTableColumn, TableColumn, TableColumnBreakpoint, TableData} from "peggirkit";
import {Keyword, serpotrackAxios, User} from "serpotrack-connector";

type Props = {
    currentUser: User | null,
    projectId: number,
    keywordDetailsToShow: Keyword | null,
    dateRange: DateRange,
    setShowAddKeywordsDialog: (show: boolean) => void,
    lastUpdated: Date,
    setLastUpdated: (date: Date) => void,
};

type DeleteKeywordsOptions = {
    showDialog: boolean,
    selectedKeywordsToDelete: Keyword[]
};

const getBaseUrl = (projectId: number, dateRange: DateRange): string => {
    if (!dateRange.from || !dateRange.to) {
        return ""
    }

    return `${process.env.SERPOTRACK_API_BASE_URL}/projects/${projectId}/keywords?from=${dateRange.from.toISOString()}&to=${dateRange.to.toISOString()}`;
}

const KeywordsDataTable = ({
                               currentUser,
                               projectId,
                               keywordDetailsToShow,
                               setKeywordDetailsToShow,
                               dateRange,
                               setShowAddKeywordsDialog,
                               lastUpdated,
                               setLastUpdated
                           }: (Props & SetKeywordDetailsToShow)) => {
    const intl = useIntl();
    const [deleteKeywordsOptions, setDeleteKeywordsOptions] = useState<DeleteKeywordsOptions>({
        showDialog: false,
        selectedKeywordsToDelete: [],
    });
    const [exportToCsvOptions, setExportToCsvOptions] = useState<ExportToCsvOptions>({
        showDialog: false,
        data: []
    });

    const columns: (TableColumn & DataTableColumn)[] = useMemo(() => {
        return [
            {
                dataName: "keyword",
                name: intl.formatMessage({id: "keyword"}),
                sortable: false,
                sorted: "desc",
                secondary: false,
            },
            {
                dataName: "id",
                name: intl.formatMessage({id: "id"}),
                hidden: true,
                sortable: false,
            },
            {
                dataName: "lastUpdated",
                name: intl.formatMessage({id: "updatedOn"}),
                sortable: false,
                secondary: true,
                formatter: (date: string): string => date === null
                    ? intl.formatMessage({id: "never"})
                    : (new Date(date).toLocaleDateString()),
                showOnBreakpoint: TableColumnBreakpoint.lg
            },
            {
                dataName: "positions",
                name: intl.formatMessage({id: "position"}),
                sortable: false,
                secondary: true,
                alignRight: true,
                formatter: positions => <KeywordsDataTablePositionFormatter positions={positions}/>
            },
        ]
    }, []);

    if (!dateRange.from || !dateRange.to) {
        return <></>;
    }

    const canExportData = currentUser !== null
        && currentUser.subscriptions.length > 0
        && currentUser.subscriptions[0].plan.canExportData;

    return (
        <>
            <DataTable
                withUrlPageParameter={false}
                scrollToTopOnPageChange={false}
                initialPageRequest={{
                    axios: serpotrackAxios,
                    pageSize: 100,
                    pageNumber: 0,
                    baseUrl: getBaseUrl(projectId, dateRange)
                }}
                columns={columns}
                onRowClick={(formattedRowData: TableData, rowIndex: number, rawData?: TableData) => {
                    if (rawData !== undefined) {
                        setKeywordDetailsToShow(rawData as Keyword);
                    }
                }}
                baseUrlChangedCallback={(rawData?: TableData) => {
                    if (rawData && keywordDetailsToShow !== null) {
                        setKeywordDetailsToShow(rawData as Keyword);
                    }
                }}
                summaryFormatter={(from: number, to: number, total: number): JSX.Element | string => (
                    <PaginationSummaryFormatter from={from} to={to} total={total}/>
                )}
                messages={{
                    next: intl.formatMessage({id: "next"}),
                    previous: intl.formatMessage({id: "previous"})
                }}
                emptyState={<KeywordsDataTableEmptyState setShowAddKeywordsDialog={setShowAddKeywordsDialog}/>}
                initialLastUpdated={lastUpdated}
                batchActions={{
                    dropdownButtonText: (itemsSelected: number) =>
                        intl.formatMessage({id: "nItemsSelected"}, {n: itemsSelected}),
                    selectRowText: intl.formatMessage({id: "selectRow"}),
                    toggleSelectAllText: intl.formatMessage({id: "toggleSelectAll"}),
                    actions: [
                        [
                            {
                                icon: canExportData ? ArrowDownTrayIcon : CurrencyDollarIcon,
                                text: intl.formatMessage({id: "exportToCsv"}),
                                disabled: !canExportData,
                                onClick: (rawData: TableData[]) => {
                                    setExportToCsvOptions({
                                        ...exportToCsvOptions,
                                        data: rawData,
                                        showDialog: true
                                    });
                                }
                            }
                        ],
                        [
                            {
                                type: "danger",
                                icon: TrashIcon,
                                text: intl.formatMessage({id: "delete"}),
                                onClick: (rawData: TableData[]) => {
                                    setDeleteKeywordsOptions({
                                        ...deleteKeywordsOptions,
                                        selectedKeywordsToDelete: rawData as Keyword[],
                                        showDialog: true
                                    });
                                }
                            }
                        ]
                    ]
                }}
            />

            <DeleteKeywordsModal
                projectId={projectId}
                keywords={deleteKeywordsOptions.selectedKeywordsToDelete}
                setKeywordDetailsToShow={setKeywordDetailsToShow}
                setKeywordsOverviewLastUpdated={setLastUpdated}
                show={deleteKeywordsOptions.showDialog}
                setShow={(show: boolean) => setDeleteKeywordsOptions({...deleteKeywordsOptions, showDialog: show})}
            />

            {
                canExportData
                    ? <KeywordsToCsvModal
                        exportToCsvOptions={exportToCsvOptions}
                        setExportToCsvOptions={setExportToCsvOptions}
                    />
                    : <></>
            }
        </>
    );
};

export default KeywordsDataTable;