import React from "react";
import router from "../../../routing/Router";
import {useIntl} from "react-intl";
import {AuthFormFooter} from "peggirkit";


const AuthFormFooterRegister = () => {
    const intl = useIntl();
    return (
        <AuthFormFooter
            message={intl.formatMessage({id: "dontHaveAnAccount"})}
            link={router.register.absolutePath()}
            linkText={intl.formatMessage({id: "register"})}
        />
    );
};

export default AuthFormFooterRegister;