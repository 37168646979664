import React from "react";
import {useIntl} from "react-intl";
import {Alert} from "peggirkit";
import {User} from "serpotrack-connector";
import {useNavigate} from "react-router-dom";
import router from "../../routing/Router";

type Props = {
    user: User | null
};

const NoPlanAlert = ({user}: Props) => {
    const intl = useIntl();
    const navigate = useNavigate();

    if (!user || user.subscriptions.length > 0) {
        return <></>;
    }

    return (
        <Alert
            className={"mt-8"}
            type={"warning"}
            title={intl.formatMessage({id: "gettingStarted"})}
            description={intl.formatMessage({id: "noPlanAlertMessage"})}
            actions={[
                {
                    text: intl.formatMessage({id: "tryForFree"}),
                    onClick: () => navigate(router.subscription.absolutePath())
                },
                {
                    text: intl.formatMessage({id: "subscribe"}),
                    onClick: () => navigate(router.subscription.absolutePath())
                },
                {
                    text: intl.formatMessage({id: "quickstartGuide"}),
                    onClick: () => window.open("https://serpotrack.com/help/getting-started/quickstart-guide", "_blank")
                },
            ]}
        />
    );
};

export default NoPlanAlert;