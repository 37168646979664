import React, {useId} from "react";
import {InputField} from "peggirkit";
import {useIntl} from "react-intl";
import {FieldProps} from "../../../fields/Field";


const ProjectNameField = ({register, disabled, fieldError, fieldName}: FieldProps) => {
    const intl = useIntl();
    const id = useId();

    return (
        <InputField
            id={id}
            displayName={intl.formatMessage({id: "name"})}
            type={"text"}
            placeholder={intl.formatMessage({id: "myProjectName"})}
            disabled={disabled}
            error={fieldError && fieldError.message}
            tip={intl.formatMessage({id: "internalProjectName"})}
            reactHookForm={{
                ...register(fieldName, {
                    required: intl.formatMessage({id: "giveProjectName"}),
                    minLength: {
                        value: 1,
                        message: intl.formatMessage({id: "mustContainAtLeastCharacters"}, {min: 1})
                    },
                    maxLength: {
                        value: 128,
                        message: intl.formatMessage({id: "canContainAtMostCharacters"}, {max: 128})
                    }
                })
            }}
        />
    );
};

export default ProjectNameField;