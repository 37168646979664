import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./styling/App.scss";
import "@fontsource/inter/variable-full.css";
import {BrowserRouter} from "react-router-dom";
import {IntlProvider} from "react-intl";
import EnglishMessages from "./messages/en-US.ts";
import {AuthMessages} from "serpotrack-frontend-authentication";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    // <React.StrictMode>
    <IntlProvider
        locale="en-US"
        messages={Object.assign({}, EnglishMessages, AuthMessages)}
    >
        <BrowserRouter>
            <App/>
        </BrowserRouter>
    </IntlProvider>
    // </React.StrictMode>
);