import React from "react";
import {Navigate, Route, Routes} from "react-router-dom";
import Projects from "./component/page/authenticated/Projects";
import SignIn from "./component/page/unauthenticated/SignIn";
import router from "./routing/Router";
import Register from "./component/page/unauthenticated/Register";
import Error from "./component/page/unauthenticated/Error";
import ResetPassword from "./component/page/unauthenticated/ResetPassword";
import ResetPasswordConfirmation from "./component/page/unauthenticated/ResetPasswordConfirmation";
import Account from "./component/page/authenticated/Account";
import ConfirmAccount from "./component/page/authenticated/ConfirmAccount";
import Subscription from "./component/page/authenticated/Subscription";
import NewProject from "./component/page/authenticated/NewProject";
import Project from "./component/page/authenticated/Project";
import {AuthProvider, ProtectedRoute} from "peggirkit";
import {finishSignInWithTotpMfa, getCurrentUser, signIn, signOut} from "serpotrack-connector";

const App = () => {
    return (
        <AuthProvider
            signIn={signIn}
            finishSignInWithTotp={finishSignInWithTotpMfa}
            signOut={signOut}
            getCurrentUser={getCurrentUser}
            onSignInRedirectTo={router.index.absolutePath()}
            onSignOutRedirectTo={router.signIn.absolutePath()}
        >
            <Routes>
                <Route path={router.auth.relativePath}>
                    <Route path={router.signIn.relativePath} element={<SignIn/>}/>
                    <Route path={router.register.relativePath} element={<Register/>}/>
                    <Route path={router.reset.relativePath}>
                        <Route
                            index
                            element={<ResetPassword/>}
                        />
                        <Route path={router.confirmReset.relativePath} element={<ResetPasswordConfirmation/>}/>
                    </Route>
                </Route>
                <Route path={router.index.relativePath} element={<Navigate to={router.projects.relativePath}/>}/>
                <Route path={router.projects.relativePath}>
                    <Route
                        index
                        element={
                            <ProtectedRoute unauthenticatedRedirect={router.signIn.absolutePath()}>
                                <Projects/>
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path={router.newProject.relativePath}
                        element={
                            <ProtectedRoute unauthenticatedRedirect={router.signIn.absolutePath()}>
                                <NewProject/>
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path={router.project.relativePath}
                        element={
                            <ProtectedRoute unauthenticatedRedirect={router.signIn.absolutePath()}>
                                <Project/>
                            </ProtectedRoute>
                        }
                    />
                </Route>
                <Route path={router.subscription.relativePath}>
                    <Route
                        index
                        element={
                            <ProtectedRoute unauthenticatedRedirect={router.signIn.absolutePath()}>
                                <Subscription/>
                            </ProtectedRoute>
                        }
                    />
                </Route>
                <Route path={router.account.relativePath}>
                    <Route
                        index
                        element={
                            <ProtectedRoute unauthenticatedRedirect={router.signIn.absolutePath()}>
                                <Account/>
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path={router.confirmAccount.relativePath}
                        element={
                            <ProtectedRoute unauthenticatedRedirect={router.signIn.absolutePath()}>
                                <ConfirmAccount/>
                            </ProtectedRoute>
                        }
                    />
                </Route>
                <Route path="*" element={<Error/>}/>
            </Routes>
        </AuthProvider>
    );
}

export default App;
