import React, {useEffect, useState} from "react";
import {useIntl} from "react-intl";
import {LockClosedIcon, UserIcon} from "@heroicons/react/24/outline";
import {breadcrumbItemAccount, breadcrumbItemProjects} from "../../../routing/Router";
import AccountTabGeneral from "../../widgets/account/AccountTabGeneral";
import AccountTabSecurity from "../../widgets/account/AccountTabSecurity";
import {BreadcrumbsContent, PageHeading, TabControl, useAuth} from "peggirkit";
import SerpotrackFrame from "./layout/SerpotrackFrame";
import {User} from "serpotrack-connector";

export type AccountTabProps = {
    user?: User
};

const Account = () => {
    const intl = useIntl();
    const authContext = useAuth();
    const [user, setUser] = useState<User>();
    const breadcrumbs: BreadcrumbsContent = {
        backText: intl.formatMessage({id: "back"}),
        items: [breadcrumbItemProjects, breadcrumbItemAccount]
    };

    useEffect(() => {
        authContext?.currentUser()
            .then(u => setUser(u));
    }, []);

    return (
        <SerpotrackFrame title={intl.formatMessage({id: "account"})}>
            <PageHeading
                breadcrumbsContent={breadcrumbs}
                title={intl.formatMessage({id: "account"})}
            />
            <TabControl
                selectTabText={intl.formatMessage({id: "selectATab"})}
                tabs={[
                    {
                        name: intl.formatMessage({id: "general"}),
                        hash: "general",
                        defaultTab: true,
                        icon: UserIcon,
                        current: true,
                        content: <AccountTabGeneral user={user}/>
                    },
                    {
                        name: intl.formatMessage({id: "security"}),
                        hash: "security",
                        icon: LockClosedIcon,
                        current: false,
                        content: <AccountTabSecurity user={user} setUser={setUser}/>
                    },
                ]}
            />
        </SerpotrackFrame>
    );
};

export default Account;