import React from "react";
import {Payload} from "recharts/types/component/DefaultTooltipContent";
import {useIntl} from "react-intl";

type Props = {
    active?: boolean,
    payload?: Payload<number, string>[],
    label?: string
};

const PositionChartTooltip = ({active, payload, label}: Props) => {
    const intl = useIntl();

    if (!active || !payload || !payload.length) {
        return;
    }

    return (
        <div className="flex flex-col gap-2 p-3 bg-white shadow ring-1 ring-black ring-opacity-5 rounded-sm outline-0">
            <span className={"text-gray-600 text-sm"}>
                {label}
            </span>
            <span className={"font-medium text-sm"}>
                {`${intl.formatMessage({id: "rank"})}: ${payload[0].value}`}
            </span>
        </div>
    );
};

export default PositionChartTooltip;