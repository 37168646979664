const Messages = {
    "contact": "Contact",
    "projects": "Projects",
    "account": "Account",
    "dashboard": "Dashboard",
    "subscription": "Subscription",
    "register": "Register",
    "signOut": "Sign out",
    "forgotPassword": "Forgot password",
    "wrongCurrentPassword": "Wrong current password entered.",
    "errorUpdatingPassword": "Error updating password.",
    "options": "Options",
    "general": "General",
    "emailAddressUsername": "E-mail address / username",
    "contactUsToUpdateEmail": "Contact us if you need to update your e-mail address",
    "security": "Security",
    "2faAddedInFuture": "Two-factor authentication will be added in the near-future.",
    "updatePassword": "Update password",
    "currentPassword": "Current password",
    "enterCurrentPassword": "Enter your current password.",
    "newPassword": "New password",
    "enterNewPassword": "Enter a new secure password.",
    "enterPassword": "Enter a secure password.",
    "repeatNewPassword": "Repeat new password",
    "repeatYourPassword": "Repeat your password",
    "download": "Download",
    "keywordLimitReached": "You have reached your keyword limit for your account. Upgrade your plan or delete keywords you don't need anymore.",
    "errorAddingKeywords": "Error adding keywords to your project.",
    "addFirstKeywords": "Add first keyword(s)",
    "showAll": "Show all",
    "showLastDays": "Show last {days} days",
    "lastNDays": "Last {days} days",
    "lastNMonths": "Last {months} months",
    "upgradeToEnableHistory": " (upgrade to enable)",
    "positionTracking": "Position tracking",
    "project": "Project",
    "deleteProject": "Delete project",
    "site": "Site",
    "device": "Device",
    "language": "Language",
    "keywords": "Keywords",
    "addKeywords": "Add keywords",
    "deleteSelection": "Delete selection",
    "exportSelectionToCsv": "Export selection to CSV",
    "confirmDelete": "Confirm delete",
    "confirmDelete.message": "Are you sure you want to delete all selected keywords? All position data will be lost.",
    "confirmDeleteProject": "Confirm delete project",
    "confirmDeleteProject.message": "Are you sure you want to delete this entire project? All keywords and their position data will be lost.",
    "addKeywordsPlaceholder": "Separate keywords by newlines...",
    "addKeywordsTip": "{currentKeywords}/{keywordLimit} keywords",
    "addKeywordsDescription": "Separate multiple keywords by new lines (enters). Note that a keyword (search query) can also be a sentence, so it doesn't have to be a single word. Max. 500 characters per keyword.",
    "enterAtLeastOneKeyword": "Enter at least one keyword.",
    "keywordsAddedToProject": "Keyword(s) added to project!",
    "youCanOnlyAddAvailableKeywords": "You can only add {availableKeywords} more keywords.",
    "keywordSizeExceeded": "Keywords may not be longer than 500 characters.",
    "keywordCannotBeEmpty": "Keywords may not be empty.",
    "projectsLimitReached": "You have reached your projects limit. Please upgrade your plan or delete unused projects to create a new one.",
    "errorCreatingProject": "Error creating project. Make sure all your fields are valid.",
    "loading": "Loading",
    "createFirstProject": "Create first project",
    "overview": "Overview",
    "create": "Create",
    "newProject": "New project",
    "name": "Name",
    "myProjectName": "My project name",
    "internalProjectName": "Internal project name for reference purposes.",
    "giveProjectName": "Give your project a name.",
    "websiteToMonitor": "Website to monitor",
    "siteUrl.tip": "The domain of the site you want to track (without http(s), trailing slash or path). Note that subdomains will be seen as different websites. 'www.' will not be considered as a subdomain and will be ignored. For example: 'domain.com' and 'subdomain.domain.com' will be seen as different sites.",
    "sitePlaceholder": "example.com",
    "enterSiteUrl": "Enter a site URL.",
    "device.tip": "Device type that will be used to perform searches.",
    "selectDevice": "Select a device.",
    "country": "Country",
    "country.tip": "Location from where searches will be performed. Not all countries may be supported by Google Search.",
    "selectCountry": "Select a country.",
    "language.tip": "Language preference to search results. Not all languages may be supported by Google Search.",
    "selectLanguage": "Select a language.",
    "select": "Select...",
    "emailInUse": "This e-mail address is already in use.",
    "errorRegistering": "Error registering account.",
    "emailAddress.tip": "This will be your username to sign in with & used for billing and recovery. WARNING: Disposable emails will result in a suspension.",
    "agreeToTerms": "I agree to the terms & conditions",
    "read": "read",
    "mustAgreeToTos": "You must agree with our terms & conditions to use our service.",
    "errorRequestingPasswordReset": "Error requesting password reset.",
    "passwordResetRequested": "We have sent you an e-mail with further instructions to set a new password. If you entered an e-mail that is not registered with us, you won't receive any mail.",
    "existingRegisteredEmail": "Existing e-mail you registered with.",
    "subscriptionsBetaAlert": "We will be adding new features during the public beta phase of Serpotrack. When we go out of beta, we will be increasing our plan prices. If you subscribe now, we won't increase the charge on your existing plan.",
    "betaPlans": "Beta plans",
    "composeYourPlan": "Compose your plan",
    "subscriptionsDescription": "By making your first payment, you authorize us to automatically debit the following subscription amounts from you (either monthly or yearly, depending on your subscription). Subscriptions will automatically renew for a next period. Terminating your plan must be done at least 1 day prior to the renewal date, otherwise you must pay for another period. By subscribing, you enter the obligation to pay the invoice before its expiration date. All your payments (& details) are safely processed and handled by our payment's provider Mollie.",
    "pricesExcludingTax": "All prices shown are excluding 21% Dutch tax. Yearly plans not available yet during beta.",
    "activePlan": "Active plan",
    "activePlanDescription": "The current plan that your account is using.",
    "hourAbbreviation": "hr.",
    "all": "All",
    "nDays": "{days} days",
    "dayAbbreviation": "{days}d",
    "confirm": "Confirm",
    "confirmCancelSubscription.description": "Are you sure you want to stop your subscription? If you continue, you won't be charged again and your plan will end on your renewal date. This action cannot be undone.",
    "invoices": "Invoices",
    "plans": "Plans",
    "mobile": "Mobile",
    "desktop": "Desktop",
    "noActions": "No actions",
    "date": "Date",
    "paid": "Paid",
    "keywordTooltip": "Search query that is used in the search engine.",
    "keywordDateTooltip": "Last date when Serpotrack updated the position.<br/>Based on your plan, we update it around every day, 2 days or 7 days.",
    "keywordPositionTooltip": "Last (organic) position of your site for the given keyword. Position 1 means you rank at the top.<br/>The lowest possible position is based on your plan and can be 50 or 100.",
    "keywordChangeTooltip": "Position change compared to the first position in the period you're looking at.",
    "keyword": "Keyword",
    "updatedOn": "Updated on",
    "positionAbbreviation": "Pos.",
    "change": "Change",
    "addedOn": "Added on",
    "lastCheckOn": "Last check on",
    "lastPosition": "Last position",
    "lastChange": "Last change",
    "noPositionDataExplanation": "Nothing to show yet. You have no active subscription, so no position data will be retrieved. Upgrade your account to enable this.",
    "noPositionDataWithPlanExplanation": "Nothing to show. It may take up to {hourlyInterval} hours before you see any data initially. If your website does not rank for this keyword, or the position is too low (greater than {maxPosition}), you also won't see any position data for this keyword. Otherwise you might have selected a period in which there was no data.",
    "ok": "Ok",
    "countryAndLang": "Country & lang.",
    "projectNameTooltip": "Name of your project.",
    "deviceTooltip": "Device type that is used to get results (desktop or mobile).",
    "countryLanguageTooltip": "Country used to perform search requests from & the search engine language.",
    "projectAddedOnTooltip": "Date when you created this project.",
    "exDutchTax": "Ex. 21% Dutch tax",
    "subscription.projects.tooltip": "Total number of projects you can create.",
    "subscription.keywords.tooltip": "Total number of keywords you can add between all your projects.",
    "subscription.maxPosition.tooltip": "The lowest site position you can track. For example, if this is 100 but your website ranks on position 105, Serpotrack won't track your position.",
    "subscription.updateInterval.tooltip": "Interval in hours when Serpotrack will retrieve your positions data.",
    "subscription.history.tooltip": "Number of days back how far you can view your position data (since Serpotrack started collecting data).",
    "maxPosition": "Max. position",
    "updateInterval": "Update interval",
    "history": "History",
    "endsOn": "Ends on",
    "renewsOn": "Renews on",
    "subscribeMonthly": "Subscribe monthly",
    "subscribeYearly": "Subscribe yearly ({formattedPrice}/yr)",
    "location": "Location",
    "rank": "Rank",
    "yourAccountHasNoActivePlan": "Your account has no active plan. {url} your account to start tracking SERP positions.",
    "upgrade": "Upgrade",
    "blog": "Blog",
    "termsConditions": "Terms & conditions",
    "privacyPolicy": "Privacy policy",
    "useCustomEntry": "Use custom entry",
    "unlimited": "Unlimited",
    "hours": "hours",
    "days": "days",
    "customPlan": "Custom plan",
    "composeCustomPlanDescription": "Compose your own custom plan and pay for what you need.",
    "upgradeToExportToCsv": "Your current plans does not support exporting your keywords to CSV files. {url} your account to enable it or subscribe to a new plan. {contactUs} if you want to upgrade your existing plan.",
    "contactUs": "Contact us",
    "exportToCsv": "Export to CSV",
    "confirmAccountNotice": "Please finish your account registration to make fully use of Serpotrack. Check your inbox for your verification link or resend the verification mail. If you can't find the mail, try checking your spam-inbox.",
    "resendConfirmation": "Resend confirmation",
    "accountNotConfirmed": "Account not confirmed yet",
    "verified": "Verified",
    "yes": "Yes",
    "no": "No",
    "confirmationMailResent": "Confirmation mail resent. Check your inbox for your confirmation link.",
    "confirmingAccount": "Confirming account",
    "confirmingAccountPleaseWait": "Please wait while we confirm your account...",
    "confirmingAccountFailed": "Could not verify your account. Either your link is invalid or your account is already confirmed. Contact us if this shouldn't happen.",
    "confirmingAccountSuccess": "You have finished your account registration. As a verified user, your account is more secure in case you lose access or need support.",
    "freeTrial": "Free trial",
    "start5DayTrial": "Start 5-day trial",
    "tryServiceForFree": "Try our service for free.",
    "verifyToStartTrial": "Verify your account to activate trial.",
    "outrankingSerpResults": "Outranking SERP results",
    "outrankingSerpResults.description": "Select a day and analyse your outranking competitors.",
    "resultsOnDate": "Results on {date}",
    "positionNumber": "Position {number}",
    "noData": "No data",
    "dontHaveAnAccount": "Don't have an account?",
    "alreadyHaveAnAccount": "Already have an account?",
    "signInAfterRegisteringError": "Your account was registered but couldn't sign in. Please try again or contact us.",
    "resetLinkExpired": "Reset link expired?",
    "requestNewReset": "Request new reset",
    "external": "External",
    "back": "Back",
    "accountTabGeneralDescription": "View your general account details.",
    "accountTabSecurityDescription": "Manage your account security.",
    "update": "Update",
    "updatePasswordDescription": "Enter the fields below to update your password.",
    "successfullySaved": "Successfully saved!",
    "success": "Success",
    "failure": "Failure",
    "goToProjects": "Go to projects",
    "amount": "Amount",
    "invoicesTabDescription": "Overview of your entire billing history.",
    "startSubscription": "Start subscription",
    "currentlyActive": "Currently active.",
    "subscriptionStopped": "Subscription stopped.",
    "more": "More",
    "view": "View",
    "next": "Next",
    "previous": "Previous",
    "paginationSummaryFormatter": "Showing {from} to {to} of {total} results",
    "noProjects": "No projects",
    "noProjectsDescriptionGetStarted": "Get started by creating a new project.",
    "createProject.sectionGeneral.title": "General",
    "createProject.sectionGeneral.description": "Specify your general project information.",
    "createProject.sectionSerp.title": "SERP targeting",
    "createProject.sectionSerp.description": "Specify settings to get targeted SERP results.",
    "id": "ID",
    "position": "Position",
    "delete": "Delete",
    "noKeywords": "No keywords",
    "noKeywordsDescriptionGetStarted": "Get started by adding your first keyword(s).",
    "keywordKeywordName": "Keyword: {keyword}",
    "positionHistory": "Position history",
    "positionHistory.description": "View how you ranked over time.",
    "noHistoryData": "No history data",
    "serpResult": "SERP result",
    "featuredSnippet": "Featured snippet",
    "noSerpDataOnDay": "No SERP data available on the selected day.",
    "customRange": "Custom range",
    "apply": "Apply",
    "pickARange": "Pick a range",
    "pickADay": "Pick a day",
    "never": "Never",
    "addedOnDate": "Added on: {date}",
    "lastUpdatedDate": "Last updated: {date}",
    "keywordDeleted": "Keyword deleted!",
    "keywordsDeleted": "Keywords deleted!",
    "deleteKeyword": "Delete keyword",
    "deleteKeywords": "Delete {keywords} keywords",
    "confirmDeleteSingleKeyword": "Are you sure you want to delete this keyword? This action cannot be undone.",
    "confirmDeleteMultipleKeyword": "Are you sure you want to delete these {keywords} keywords? This action cannot be undone.",
    "selectRow": "Select row",
    "toggleSelectAll": "Toggle select all",
    "nItemsSelected": "{n} selected",
    "exportToCsvModalBodyText": "Download your CSV-export.",
    "edit": "Edit",
    "selectATab": "Select a tab",
    "rankingUrls": "Ranking URLs",
    "rankingUrls.description": "Overview of all project URLs that were ranked in this period.",
    "visit": "Visit",
    "url": "URL",
    "avgPosition": "Avg. position",
    "bestPosition": "Best position",
    "worstPosition": "Worst position",
    "errorUpdatingProject": "Error updating project.",
    "updateProjectDescription": "You can only edit the name of the project. Other properties cannot be edited as it will result in inconsistent results.",
    "editProject": "Edit project",
    "tryForFree": "Try for free",
    "subscribe": "Subscribe",
    "gettingStarted": "Getting started",
    "noPlanAlertMessage": "You currently have no plan. Activate a plan to start creating projects and tracking your SERP positions. If you haven't already, you can start a free trial to try our service. Otherwise, you can subscribe to a custom plan based on your needs.",
    "noInvoices": "No invoices",
    "noInvoicesDescription": "All your paid invoices will appear here when you subscribe to a plan.",
    "question": "Question",
    "peopleAlsoAskNoDataDescription": "There were no \"People also ask\"-questions for this keyword on this date.",
    "peopleAlsoAsk": "People also ask",
    "peopleAlsoAskSectionDescription": "Questions people often search for that are related to your keyword.",
    "selectDay": "Select day...",
    "positionChangeTooltip": "Change from {from} - {to}",
    "openInNewTab": "Open in new tab",
    "backupCodes": "Backup codes",
    "backupCodes.description": "Here are your 2FA backup codes. If you ever lose access to your authenticator and cannot generate any codes, contact us with any of your codes. Important: Keep your backup codes secret and store them in a safe place. You won't be able to see them again from Serpotrack.",
    "unexpectedServerError": "Unexpected server error!",
    "enable2Fa": "Enable 2FA",
    "disable2Fa": "Disable 2FA",
    "disable": "Disable",
    "enable": "Enable",
    "setup2Fa.step-1": "Download and install an authenticator app (e.g. Google Authenticator, Aegis, etc.). Scan the QR-code or enter the secret in your app:",
    "setup2Fa.step-2": "Enter the code that has been generated by your authenticator in the field below:",
    "generated6DigitCode": "Generated 6-digit code",
    "6DigitCode": "6-digit code",
    "totpTip": "Your code changes every 30 seconds, make sure to enter the most recent one.",
    "enabled": "Enabled",
    "notEnabled": "Not enabled",
    "2faNotEnabledWarning": "Your account is not protected with 2FA. Enable it now to add an extra layer of security to your account. It requires you to enter a code from your authenticator app every time you sign in.",
    "qrCode": "QR-code",
    "scanWithYourApp": "Scan this code with your app",
    "twoFactorAuthentication2Fa": "Two-factor authentication (2FA)",
    "totpFullName": "Time-based one-time password (TOTP)",
    "revealSecret": "Reveal secret",
    "copy": "Copy",
    "secret": "Secret",
    "confirmDisable2Fa": "Are you sure you want to disable 2FA on your account? This impacts the safety of your account.",
    "2FaDisabled": "2FA disabled",
    "generateBackupCodesDescription": "Generate a new set of backup codes which can be used to access your account if you lose access to your 2FA codes. Invalidates the old ones.",
    "generate": "Generate",
    "confirmGenerateBackupCodes": "Generating new 2FA backup codes will invalidate your previous codes. Are you sure you want to proceed? This action cannot be undone.",
    "confirmNewBackupCode": "Confirm new backup codes",
    "confirmEnable": "Confirm enable",
    "confirmDisable": "Confirm disable",
    "quickstartGuide": "Quickstart guide",
    "help": "Help",
    "registrationsDisabledMessage": "Thanks for your interest in our service. We have temporarily disabled new beta account registrations.",
};

export default Messages;