import React from "react";
import DeleteSection from "./DeleteSection";
import DatesSection from "./DatesSection";
import {SetKeywordDetailsToShow, SetKeywordsOverviewLastUpdated} from "../../../page/authenticated/Project";
import {Keyword} from "serpotrack-connector";

type Props = {
    projectId: number,
    keyword: Keyword
};

const FooterSection = ({
                           projectId,
                           keyword,
                           setKeywordDetailsToShow,
                           setKeywordsOverviewLastUpdated
                       }: Props & SetKeywordDetailsToShow & SetKeywordsOverviewLastUpdated) => {
    return (
        <div
            className={"flex flex-col gap-4 items-start"}
        >
            <DeleteSection
                projectId={projectId}
                setKeywordDetailsToShow={setKeywordDetailsToShow}
                setKeywordsOverviewLastUpdated={setKeywordsOverviewLastUpdated}
                keywords={[keyword]}
            />
            <DatesSection keyword={keyword}/>
        </div>
    );
};

export default FooterSection;