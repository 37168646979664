import React, {useMemo} from "react";
import {useIntl} from "react-intl";
import {DataTable, EmptyState, SectionHeading, TableButton} from "peggirkit";
import {serpotrackAxios} from "serpotrack-connector";
import PaginationSummaryFormatter from "../PaginationSummaryFormatter";
import {BanknotesIcon} from "@heroicons/react/24/outline";
import {formatMoney} from "./MoneyUtils";
import {TableColumn} from "peggirkit/dist/components/Table/TableColumns";
import {DataTableColumn} from "peggirkit/dist/components/Table/DataTable/DataTable";


const SubscriptionTabInvoices = () => {
    const intl = useIntl();
    const columns: (TableColumn & DataTableColumn)[] = useMemo(() => ([
        {
            dataName: "paidOn",
            name: intl.formatMessage({id: "date"}),
            sortable: true,
            sorted: "desc",
            formatter: (paidOn: string) => new Date(paidOn).toLocaleDateString()
        },
        {
            dataName: "itemPriceInTax",
            name: intl.formatMessage({id: "amount"}),
            sortable: false,
            secondary: true,
            formatter: (itemPriceInTax: number) => formatMoney(itemPriceInTax)
        },
        {
            dataName: "id",
            name: intl.formatMessage({id: "options"}),
            sortable: false,
            secondary: true,
            hideLabel: true,
            alignRight: true,
            formatter: (id: number) => (
                <TableButton
                    text={intl.formatMessage({id: "download"})}
                    href={`${process.env.SERPOTRACK_API_BASE_URL}/subscriptions/invoices/${id}`}
                />
            )
        }
    ]), []);

    return (
        <>
            <SectionHeading
                title={intl.formatMessage({id: "invoices"})}
                description={intl.formatMessage({id: "invoicesTabDescription"})}
            />

            <DataTable
                className={"mt-8"}
                withUrlPageParameter={true}
                scrollToTopOnPageChange={true}
                initialPageRequest={{
                    axios: serpotrackAxios,
                    baseUrl: `${process.env.SERPOTRACK_API_BASE_URL}/subscriptions/invoices`,
                    pageSize: 10,
                    pageNumber: 0
                }}
                columns={columns}
                summaryFormatter={(from: number, to: number, total: number): JSX.Element | string => (
                    <PaginationSummaryFormatter from={from} to={to} total={total}/>
                )}
                messages={{
                    next: intl.formatMessage({id: "next"}),
                    previous: intl.formatMessage({id: "previous"})
                }}
                emptyState={
                    <EmptyState
                        className={"border-t"}
                        icon={BanknotesIcon}
                        title={intl.formatMessage({id: "noInvoices"})}
                        description={intl.formatMessage({id: "noInvoicesDescription"})}
                    />
                }
            />
        </>
    );
};

export default SubscriptionTabInvoices;