import React from "react";
import {useIntl} from "react-intl";
import router from "../../../routing/Router";
// @ts-ignore
import logo from "../../../assets/img/icon.svg";
import {AuthFormFooter, AuthFormPanel} from "peggirkit";
import {SerpotrackAuthFrame} from "serpotrack-frontend-authentication";
import AuthFormRegisterDisabled from "../../widgets/auth/AuthFormRegisterDisabled";

const Register = () => {
    const intl = useIntl();
    return (
        <SerpotrackAuthFrame
            logo={logo}
            onSignedInRedirectTo={router.projects.absolutePath()}
            title={intl.formatMessage({id: "register"})}
        >
            <AuthFormPanel
                form={<AuthFormRegisterDisabled/>}
                footer={
                    <AuthFormFooter
                        message={intl.formatMessage({id: "alreadyHaveAnAccount"})}
                        link={router.signIn.absolutePath()}
                        linkText={intl.formatMessage({id: "signIn"})}
                    />
                }
            />
        </SerpotrackAuthFrame>
    );
};

export default Register;