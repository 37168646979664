import React, {useState} from "react";
import {useIntl} from "react-intl";
import UpdatePasswordModal from "./UpdatePasswordModal";
import {DescriptionList, SectionHeading} from "peggirkit";
import Enable2FaModal from "./mfa/totp/enable/Enable2FaModal";
import {AccountTabProps} from "../../page/authenticated/Account";
import {User} from "serpotrack-connector";
import {toggle2FaSettingsItem} from "./mfa/totp/settingsItem/Toggle2FaSettingsItem";
import Disable2FaModal from "./mfa/totp/disable/Disable2FaModal";
import {generateBackupCodesSettingsItem} from "./mfa/backupCodes/BackupCodesSettingsItem";
import GenerateBackupCodesModal from "./mfa/backupCodes/GenerateBackupCodesModal";

type Props = {
    setUser: (user: User) => void
};

const AccountTabSecurity = ({user, setUser}: AccountTabProps & Props) => {
    const intl = useIntl();
    const [showUpdatePasswordModal, setShowUpdatePasswordModal] = useState(false);
    const [showEnable2FaModal, setShowEnable2FaModal] = useState(false);
    const [showDisable2FaModal, setShowDisable2FaModal] = useState(false);
    const [showGenerateBackupCodesModal, setShowGenerateBackupCodesModal] = useState(false);

    return (
        <>
            <SectionHeading
                title={intl.formatMessage({id: "security"})}
                description={intl.formatMessage({id: "accountTabSecurityDescription"})}
            />
            <DescriptionList
                whitespaceNormal={true}
                items={[
                    {
                        key: intl.formatMessage({id: "password"}),
                        value: "•••••••••••••••",
                        options: [
                            {
                                text: intl.formatMessage({id: "update"}),
                                onClick: () => setShowUpdatePasswordModal(true),
                            }
                        ],
                    },
                    toggle2FaSettingsItem(user, setShowEnable2FaModal, setShowDisable2FaModal),
                    ...generateBackupCodesSettingsItem(user, setShowGenerateBackupCodesModal),
                ]}
            />

            <UpdatePasswordModal
                show={showUpdatePasswordModal}
                onClose={() => setShowUpdatePasswordModal(false)}
            />

            {
                user && <Enable2FaModal
                    show={showEnable2FaModal}
                    setShow={setShowEnable2FaModal}
                    user={user}
                    setUser={setUser}
                />
            }

            {
                user && <Disable2FaModal
                    show={showDisable2FaModal}
                    setShow={setShowDisable2FaModal}
                    user={user}
                    setUser={setUser}
                />
            }

            {
                user && <GenerateBackupCodesModal
                    show={showGenerateBackupCodesModal}
                    setShow={setShowGenerateBackupCodesModal}
                />
            }
        </>
    );
};

export default AccountTabSecurity;