import React, {useEffect, useState} from "react";
import SubscriptionTabSectionStartSubscription from "./SubscriptionTabSectionStartSubscription";
import SubscriptionTabSectionActiveSubscription from "./SubscriptionTabSectionActiveSubscription";
import {SectionHeadingSkeleton, useAuth} from "peggirkit";
import {User} from "serpotrack-connector";

const SubscriptionTabPlans = () => {
    const [user, setUser] = useState<User | null>(null);
    const authContext = useAuth();

    useEffect(() => {
        authContext?.currentUser().then((user: User) => {
            setUser(user);
        });
    }, []);

    if (user === null) {
        return <SectionHeadingSkeleton/>;
    }

    if (user.subscriptions.length === 0) {
        return (
            <SubscriptionTabSectionStartSubscription
                verified={user.verified}
                usedTrial={user.usedTrial}
            />
        );
    }

    return <SubscriptionTabSectionActiveSubscription subscription={user.subscriptions[0]}/>;
};

export default SubscriptionTabPlans;