import React from "react";
import {useIntl} from "react-intl";
import {Table, TableColumn, TextSkeleton} from "peggirkit";

type Props = {
    columns: TableColumn[]
};

const RankingUrlsTablePlaceholder = ({columns}: Props) => {
    const intl = useIntl();
    return (
        <Table
            hideHeader={true}
            columns={columns}
            data={[...Array(1)].map(() => ({
                [intl.formatMessage({id: "url"})]:
                    <TextSkeleton
                        pulse={false}
                        width={"max-w-[300px]"}
                    />,
                [intl.formatMessage({id: "visit"})]:
                    <TextSkeleton
                        pulse={false}
                        width={"max-w-[50px]"}
                    />
            }))}
        />
    );
};

export default RankingUrlsTablePlaceholder;