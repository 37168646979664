import React from "react";
import AuthFormFooterRegister from "../../widgets/auth/AuthFormFooterRegister";
import {SignIn as SignInPage} from "serpotrack-frontend-authentication";
// @ts-ignore
import logo from "../../../assets/img/icon.svg";
import router from "../../../routing/Router";

const SignIn = () => {
    return (
        <SignInPage
            logo={logo}
            onSignedInRedirectTo={router.projects.absolutePath()}
            forgotPasswordUrl={router.reset.absolutePath()}
            footer={<AuthFormFooterRegister/>}
        />
    );
};

export default SignIn;