import {createIntl, createIntlCache} from "react-intl";
// @ts-ignore
import EnglishMessages from "../messages/en-US";
import {BreadcrumbItem} from "peggirkit";

type Route = {
    relativePath: string,
    absolutePath: (...param: any) => string
}

type Router = {
    [routes: string]: Route
}

// Base:
const index: Route = {
    relativePath: "/",
    absolutePath: () => "/"
};

// Unauthenticated:
const authPart = "auth";
const auth: Route = {
    relativePath: authPart,
    absolutePath: () => `${index.absolutePath()}${authPart}`
};

const signInPart = "sign-in";
const signIn: Route = {
    relativePath: signInPart,
    absolutePath: () => `${auth.absolutePath()}/${signInPart}`
};

const registerPart = "register";
const register: Route = {
    relativePath: registerPart,
    absolutePath: () => `${auth.absolutePath()}/${registerPart}`
};

const resetPart = "reset";
const reset: Route = {
    relativePath: resetPart,
    absolutePath: () => `${auth.absolutePath()}/${resetPart}`
};

const confirmResetPart = "confirm";
const confirmReset: Route = {
    relativePath: confirmResetPart,
    absolutePath: () => `${reset.absolutePath()}/${confirmResetPart}`
};

// Authenticated:
const projectsPart = "projects";
const projects: Route = {
    relativePath: projectsPart,
    absolutePath: () => `${index.absolutePath()}${projectsPart}`
};

const newProjectPart = "new";
const newProject: Route = {
    relativePath: newProjectPart,
    absolutePath: () => `${projects.absolutePath()}/${newProjectPart}`
};

const project: Route = {
    relativePath: ":projectId",
    absolutePath: (projectId: number) => `${projects.absolutePath()}/${projectId}`
};

const subscriptionPart = "subscription";
const subscription: Route = {
    relativePath: subscriptionPart,
    absolutePath: () => `${index.absolutePath()}${subscriptionPart}`
};

const accountPart = "account";
const account: Route = {
    relativePath: accountPart,
    absolutePath: () => `${index.absolutePath()}${accountPart}`
};

const confirmAccountPart = "confirm";
const confirmAccount: Route = {
    relativePath: confirmAccountPart,
    absolutePath: () => `${account.absolutePath()}/${confirmAccountPart}`
};

const router: Router = {};
router.index = index;
router.auth = auth;
router.signIn = signIn;
router.register = register;
router.reset = reset;
router.confirmReset = confirmReset;
router.projects = projects;
router.newProject = newProject;
router.project = project;
router.subscription = subscription;
router.account = account;
router.confirmAccount = confirmAccount;

// Breadcrumbs:
const cache = createIntlCache();
const intl = createIntl({locale: 'en-US', messages: EnglishMessages}, cache);

export const breadcrumbItemProjects: BreadcrumbItem = {
    name: intl.formatMessage({id: "projects"}),
    href: router.projects.absolutePath()
};

export const breadcrumbItemProject = (projectId: number, projectName?: string): BreadcrumbItem => ({
    name: projectName || intl.formatMessage({id: "project"}),
    href: router.project.absolutePath(projectId)
});

export const breadcrumbItemNewProject: BreadcrumbItem = {
    name: intl.formatMessage({id: "newProject"}),
    href: router.newProject.absolutePath()
};

export const breadcrumbItemAccount: BreadcrumbItem = {
    name: intl.formatMessage({id: "account"}),
    href: router.account.absolutePath()
};

export const breadcrumbItemConfirmAccount: BreadcrumbItem = {
    name: intl.formatMessage({id: "confirmingAccount"}),
    href: router.confirmAccount.absolutePath()
};

export const breadcrumbItemSubscription: BreadcrumbItem = {
    name: intl.formatMessage({id: "subscription"}),
    href: router.subscription.absolutePath()
};

export default router;