import React, {useEffect, useState} from "react";
import {useIntl} from "react-intl";
import {CalendarIcon} from "@heroicons/react/24/outline";
import OutrankingSerpTablePlaceholder from "./OutrankingSerpTablePlaceholder";
import OutrankingSerpTable from "./OutrankingSerpTable";
import {endOfDay, startOfDay} from "date-fns";
import {Button, SectionHeading} from "peggirkit";
import {Keyword, User} from "serpotrack-connector";
import KeywordQueryDayPicker from "../KeywordQueryDayPicker";

type Props = {
    projectId: number,
    keyword: Keyword,
    user: User | null,
};

const outrankingSerpUrl = (projectId: number, keywordId: number, date: Date): string => {
    const from = startOfDay(date).toISOString();
    const to = endOfDay(date).toISOString();
    return `${process.env.SERPOTRACK_API_BASE_URL}/projects/${projectId}/keywords/${keywordId}?from=${from}&to=${to}`;
}

const OutrankingSerpSection = ({projectId, keyword, user}: Props) => {
    const intl = useIntl();
    const [kw, setKw] = useState(keyword);
    const [date, setDate] = useState<Date | null>(kw.positions.length > 0
        ? new Date(kw.positions[kw.positions.length - 1].date)
        : null);
    const [baseUrl, setBaseUrl] = useState<string | null>(date === null
        ? null
        : outrankingSerpUrl(projectId, keyword.id, date));
    const [showDayPicker, setShowDayPicker] = useState(false);

    if (keyword.id !== kw.id) {
        setKw(keyword);
    }

    useEffect(() => {
        const newDate = kw.positions.length > 0
            ? new Date(kw.positions[kw.positions.length - 1].date)
            : null;
        const newBaseUrl = newDate === null
            ? null
            : outrankingSerpUrl(projectId, kw.id, newDate);
        setDate(newDate);
        setBaseUrl(newBaseUrl);
    }, [kw]);

    useEffect(() => {
        if (date !== null) {
            setBaseUrl(outrankingSerpUrl(projectId, kw.id, date));
        }
    }, [date]);

    return (
        <div>
            <SectionHeading
                title={intl.formatMessage({id: "outrankingSerpResults"})}
                description={intl.formatMessage({id: "outrankingSerpResults.description"})}
                className={"mb-4"}
            />

            <Button
                icon={CalendarIcon}
                type={"white"}
                text={date !== null ? date.toLocaleDateString() : intl.formatMessage({id: "selectDay"})}
                onClick={() => setShowDayPicker(true)}
            />

            <div className={"mt-3"}>
                {
                    baseUrl !== null
                        ? <OutrankingSerpTable baseUrl={baseUrl}/>
                        : <OutrankingSerpTablePlaceholder/>
                }
            </div>

            <KeywordQueryDayPicker
                show={showDayPicker}
                setShow={setShowDayPicker}
                date={date || new Date()}
                setDate={setDate}
                user={user}
            />
        </div>
    );
};

export default OutrankingSerpSection;