import React, {useState} from "react";
import PlanContainer from "./PlanContainer";
import PlanCustom from "./PlanCustom";
import PlanTrial from "./PlanTrial";
import {useIntl} from "react-intl";
import {Alert, SectionHeading} from "peggirkit";

type Props = {
    verified: boolean,
    usedTrial: boolean
};

const SubscriptionTabSectionStartSubscription = ({verified, usedTrial}: Props) => {
    const intl = useIntl();
    const [disabled, setDisabled] = useState(false);

    return (
        <>
            <SectionHeading
                title={intl.formatMessage({id: "startSubscription"})}
                description={intl.formatMessage({id: "subscriptionsDescription"})}
            />

            <Alert
                type={"info"}
                title={intl.formatMessage({id: "betaPlans"})}
                description={intl.formatMessage({id: "subscriptionsBetaAlert"})}
            />

            <PlanContainer>
                <PlanCustom
                    disabled={disabled}
                    setDisabled={setDisabled}
                />
                {
                    !usedTrial
                        ? <PlanTrial
                            disabled={disabled || !verified}
                            setDisabled={setDisabled}
                            verified={verified}
                        />
                        : <></>
                }
            </PlanContainer>
        </>
    );
};

export default SubscriptionTabSectionStartSubscription;