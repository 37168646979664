import React from "react";
import {FormattedMessage} from "react-intl";

const PositionChartEmptyState = () => {
    return (
        <div className={"w-full h-[200px] relative"}>
            <span
                className={"absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 block font-bold text-gray-400 text-md"}
            >
                <FormattedMessage id={"noHistoryData"}/>
            </span>
            <svg
                width="100%"
                height="100%"
                viewBox="0 0 1000 500"
                preserveAspectRatio="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M 0,496.7819807457731 C 16.599999999999998,492.1126863228224 49.8,485.4970137710351 83,473.4355086310195 C 116.2,461.3740034910039 132.8,452.23514621059553 166,436.47445504569527 C 199.2,420.713763880795 215.8,412.8222677114763 249,394.6320528065181 C 282.2,376.44183790155995 298.8,360.3804143521215 332,345.52338052090454 C 365.2,330.6663466896876 381.8,321.65808840963456 415,320.3468836504335 C 448.2,319.0356788912324 464.8,342.48793657958555 498,338.9673567248992 C 531.2,335.4467768702129 547.8,314.67371791498454 581,302.7439843770018 C 614.2,290.8142508390191 630.8,294.2957095472192 664,279.31868903498565 C 697.2,264.3416685227521 713.8,262.0191345697908 747,227.85888181583425 C 780.2,193.69862906187768 796.8,129.59066734065124 830,108.51742526520286 C 863.2,87.4441831897545 879.8,124.91962226369327 913,122.49267143859242 C 946.2,120.06572061349158 979.4,101.6046711994774 996,96.38267113969863,L 1000 500,L 0 500Z"
                    fill="#f9fafb"
                />
                <path
                    d="M 0,496.7819807457731 C 16.599999999999998,492.1126863228224 49.8,485.4970137710351 83,473.4355086310195 C 116.2,461.3740034910039 132.8,452.23514621059553 166,436.47445504569527 C 199.2,420.713763880795 215.8,412.8222677114763 249,394.6320528065181 C 282.2,376.44183790155995 298.8,360.3804143521215 332,345.52338052090454 C 365.2,330.6663466896876 381.8,321.65808840963456 415,320.3468836504335 C 448.2,319.0356788912324 464.8,342.48793657958555 498,338.9673567248992 C 531.2,335.4467768702129 547.8,314.67371791498454 581,302.7439843770018 C 614.2,290.8142508390191 630.8,294.2957095472192 664,279.31868903498565 C 697.2,264.3416685227521 713.8,262.0191345697908 747,227.85888181583425 C 780.2,193.69862906187768 796.8,129.59066734065124 830,108.51742526520286 C 863.2,87.4441831897545 879.8,124.91962226369327 913,122.49267143859242 C 946.2,120.06572061349158 979.4,101.6046711994774 996,96.38267113969863"
                    fill="none"
                    stroke="#f3f4f6"
                    strokeWidth="10px"
                />
            </svg>
        </div>
    );
};

export default PositionChartEmptyState;