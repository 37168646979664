import React from "react";
import {FormattedMessage} from "react-intl";
import {Keyword} from "serpotrack-connector";

type Props = {
    keyword: Keyword
};

const DatesSection = ({keyword}: Props) => {
    return (
        <div className={"flex flex-col text-xs text-gray-400"}>
            <span>
                <FormattedMessage
                    id="addedOnDate"
                    values={{date: new Date(keyword.addedOn).toLocaleDateString()}}
                />
            </span>
            <span>
                <FormattedMessage
                    id="lastUpdatedDate"
                    values={{
                        date: keyword.lastUpdated ? new Date(keyword.lastUpdated).toLocaleDateString() : "never"
                    }}
                />
            </span>
        </div>
    );
};

export default DatesSection;