import React from "react";
import KeywordDetails, {KeywordDetailsDisplay} from "./KeywordDetails";
import {useIntl} from "react-intl";
import {SetKeywordDetailsToShow} from "../../../page/authenticated/Project";
import {SlideOver, SlideOverProps} from "peggirkit";
import {Keyword} from "serpotrack-connector";

type Props = {
    keyword: Keyword | null,
    projectId: number,
};

const KeywordDetailsSlideOver = ({
                                     currentUser,
                                     keyword,
                                     projectId,
                                     open,
                                     setOpen,
                                     setKeywordDetailsToShow,
                                     dateRange,
                                     setKeywordsOverviewLastUpdated
                                 }: (SlideOverProps & SetKeywordDetailsToShow & KeywordDetailsDisplay & Props)) => {
    const intl = useIntl();

    return (
        <SlideOver
            open={open}
            setOpen={setOpen}
            title={intl.formatMessage({id: "keywordKeywordName"}, {keyword: keyword?.keyword || ""})}
            afterClose={() => setKeywordDetailsToShow(null)}
        >
            {
                keyword !== null
                    ? <KeywordDetails
                        currentUser={currentUser}
                        keyword={keyword}
                        projectId={projectId}
                        dateRange={dateRange}
                        setKeywordDetailsToShow={setKeywordDetailsToShow}
                        setKeywordsOverviewLastUpdated={setKeywordsOverviewLastUpdated}
                    />
                    : <></>
            }
        </SlideOver>
    );
};

export default KeywordDetailsSlideOver;