import React from "react";
import {useIntl} from "react-intl";
import {BanknotesIcon, RectangleStackIcon} from "@heroicons/react/24/outline";
import {breadcrumbItemProjects, breadcrumbItemSubscription} from "../../../routing/Router";
import SubscriptionTabInvoices from "../../widgets/subscription/SubscriptionTabInvoices";
import SubscriptionTabPlans from "../../widgets/subscription/SubscriptionTabPlans";
import {BreadcrumbsContent, PageHeading, TabControl} from "peggirkit";
import SerpotrackFrame from "./layout/SerpotrackFrame";

const Subscription = () => {
    const intl = useIntl();
    const breadcrumbs: BreadcrumbsContent = {
        backText: intl.formatMessage({id: "back"}),
        items: [breadcrumbItemProjects, breadcrumbItemSubscription]
    };

    return (
        <SerpotrackFrame title={intl.formatMessage({id: "subscription"})}>
            <PageHeading
                breadcrumbsContent={breadcrumbs}
                title={intl.formatMessage({id: "subscription"})}
            />
            <TabControl
                selectTabText={intl.formatMessage({id: "selectATab"})}
                tabs={[
                    {
                        name: intl.formatMessage({id: "plans"}),
                        hash: "plans",
                        icon: RectangleStackIcon,
                        current: true,
                        defaultTab: true,
                        content: <SubscriptionTabPlans/>
                    },
                    {
                        name: intl.formatMessage({id: "invoices"}),
                        hash: "invoices",
                        icon: BanknotesIcon,
                        current: false,
                        content: <SubscriptionTabInvoices/>
                    },
                ]}
            />
        </SerpotrackFrame>
    );
};

export default Subscription;