import React from "react";
import {ArrowDownRightIcon, ArrowLongRightIcon, ArrowUpRightIcon, MinusSmallIcon} from "@heroicons/react/20/solid";
import {Badge} from "peggirkit";
import {KeywordPosition} from "serpotrack-connector";
import {useIntl} from "react-intl";

type Props = {
    positions: KeywordPosition[]
};

const KeywordsDataTablePositionFormatter = ({positions}: Props) => {
    const intl = useIntl();

    if (positions.length === 0) {
        return <>-</>;
    }

    const mostRecentRank = positions[positions.length - 1].rank;
    if (positions.length === 1) {
        return <>{mostRecentRank}</>;
    }

    const earliestRank = positions[0].rank;
    const rankChange = earliestRank - mostRecentRank;
    const tooltipText = intl.formatMessage({id: "positionChangeTooltip"}, {
        from: new Date(positions[0].date).toLocaleDateString(),
        to: new Date(positions[positions.length - 1].date).toLocaleDateString()
    });

    let badge;
    if (rankChange < 0) {
        badge = <Badge
            type={"red"}
            icon={ArrowDownRightIcon}
            content={Math.abs(rankChange) + ""}
            tooltip={tooltipText}
        />;
    } else if (rankChange > 0) {
        badge = <Badge
            type={"green"}
            icon={ArrowUpRightIcon}
            content={Math.abs(rankChange) + ""}
            tooltip={tooltipText}
        />;
    } else {
        badge = <Badge
            type={"gray"}
            icon={MinusSmallIcon}
            content={Math.abs(rankChange) + ""}
            tooltip={tooltipText}
        />;
    }

    return (
        <div className={"flex justify-end items-center gap-4"}>
            {badge}
            <div className={"flex justify-end items-center gap-1"}>
                <span>{earliestRank}</span>
                <ArrowLongRightIcon opacity={"0.55"} width={"20"}/>
                <span>{mostRecentRank}</span>
            </div>
        </div>
    );
};

export default KeywordsDataTablePositionFormatter;