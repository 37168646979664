import React from "react";
import {useIntl} from "react-intl";
import {breadcrumbItemNewProject, breadcrumbItemProjects} from "../../../routing/Router";
import NewProjectForm from "../../widgets/projects/new/NewProjectForm";
import {BreadcrumbsContent, PageHeading} from "peggirkit";
import SerpotrackFrame from "./layout/SerpotrackFrame";

const NewProject = () => {
    const intl = useIntl();
    const breadcrumbs: BreadcrumbsContent = {
        backText: intl.formatMessage({id: "back"}),
        items: [breadcrumbItemProjects, breadcrumbItemNewProject]
    };

    return (
        <SerpotrackFrame title={intl.formatMessage({id: "newProject"})}>
            <PageHeading
                breadcrumbsContent={breadcrumbs}
                title={intl.formatMessage({id: "newProject"})}
            />

            <NewProjectForm/>
        </SerpotrackFrame>
    );
};

export default NewProject;