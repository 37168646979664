import React, {useMemo} from "react";
import PaginationSummaryFormatter from "../../../PaginationSummaryFormatter";
import {useIntl} from "react-intl";
import {InformationCircleIcon} from "@heroicons/react/24/outline";
import {DataTable, EmptyState, TableColumn} from "peggirkit";
import {serpotrackAxios} from "serpotrack-connector";

type Props = {
    baseUrl: string
};

const OutrankingSerpTable = ({baseUrl}: Props) => {
    const intl = useIntl();
    const columns: TableColumn[] = useMemo(() => ([
        {
            dataName: "question",
            name: intl.formatMessage({id: "question"}),
            sortable: false,
            secondary: false,
            tdClassName: "font-normal text-black"
        }
    ]), []);

    return (
        <DataTable
            withUrlPageParameter={false}
            scrollToTopOnPageChange={false}
            initialPageRequest={{
                axios: serpotrackAxios,
                pageSize: 4,
                pageNumber: 0,
                baseUrl: baseUrl
            }}
            columns={columns}
            messages={{
                next: intl.formatMessage({id: "next"}),
                previous: intl.formatMessage({id: "previous"})
            }}
            summaryFormatter={(from: number, to: number, total: number): JSX.Element | string => (
                <PaginationSummaryFormatter from={from} to={to} total={total}/>
            )}
            emptyState={
                <EmptyState
                    className={"border-t"}
                    icon={InformationCircleIcon}
                    title={intl.formatMessage({id: "noData"})}
                    description={intl.formatMessage({id: "peopleAlsoAskNoDataDescription"})}
                />
            }
            compactPagination
        />
    );
};

export default OutrankingSerpTable;