import React, {useEffect, useState} from "react";
import {useIntl} from "react-intl";
import router, {breadcrumbItemConfirmAccount, breadcrumbItemProjects} from "../../../routing/Router";
import {useSearchParams} from "react-router-dom";
import {Alert, BreadcrumbsContent, PageHeading} from "peggirkit";
import SerpotrackFrame from "./layout/SerpotrackFrame";
import {confirmAccount} from "serpotrack-connector";

const ConfirmAccount = () => {
    const intl = useIntl();
    const [search] = useSearchParams();
    const [hasFailed, setHasFailed] = useState(false);
    const [hasSucceeded, setHasSucceeded] = useState(false);
    const token = search.get("token");
    const breadcrumbs: BreadcrumbsContent = {
        backText: intl.formatMessage({id: "back"}),
        items: [breadcrumbItemProjects, breadcrumbItemConfirmAccount]
    };

    useEffect(() => {
        if (token === null) {
            setHasFailed(true);
            return;
        }

        confirmAccount(token).then(() => {
            setHasSucceeded(true);
        }).catch(() => {
            setHasFailed(true);
        });
    }, []);

    const loadingAlert = () => (
        <Alert
            type={"info"}
            title={intl.formatMessage({id: "confirmingAccount"})}
            description={intl.formatMessage({id: "confirmingAccountPleaseWait"})}
        />
    );

    const failedAlert = () => (
        <Alert
            type={"danger"}
            title={intl.formatMessage({id: "failure"})}
            description={intl.formatMessage({id: "confirmingAccountFailed"})}
            actions={[{
                text: intl.formatMessage({id: "contact"}),
                onClick: () => window.location.href = "https://serpotrack.com/contact"
            }]}
        />
    );

    const successAlert = () => (
        <Alert
            type={"success"}
            title={intl.formatMessage({id: "success"})}
            description={intl.formatMessage({id: "confirmingAccountSuccess"})}
            actions={[{
                text: intl.formatMessage({id: "goToProjects"}),
                onClick: () => window.location.href = router.projects.absolutePath()
            }]}
        />
    );

    return (
        <SerpotrackFrame title={intl.formatMessage({id: "confirmingAccount"})}>
            <PageHeading
                breadcrumbsContent={breadcrumbs}
                title={intl.formatMessage({id: "confirmingAccount"})}
            />

            <div className="mt-6">
                {hasFailed && failedAlert()}
                {hasSucceeded && successAlert()}
                {(!hasFailed && !hasSucceeded) && loadingAlert()}
            </div>
        </SerpotrackFrame>
    );
};

export default ConfirmAccount;