import React, {useEffect, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {AlertModal, AlertText, Form} from "peggirkit";
import {Project, updateProject} from "serpotrack-connector";
import {useForm} from "react-hook-form";
import ProjectNameField from "../projects/new/fields/ProjectNameField";

type Props = {
    show: boolean,
    setShow: (show: boolean) => void,
    project: Project
    setProject: (project: Project) => void,
};

type FormValues = {
    name: string,
};

const EditProjectModal = ({show, setShow, project, setProject}: Props) => {
    const intl = useIntl();
    const {register, getValues, setValue, handleSubmit, formState: {errors}} = useForm<FormValues>();
    const [loading, setLoading] = useState(false);
    const [formError, setFormError] = useState<string | null>(null);

    useEffect(() => {
        if (show) {
            setValue("name", project.name);
        }
    }, [show]);

    useEffect(() => {
        if (!loading) {
            return;
        }

        updateProject(project.id, getValues().name).then((updatedProject: Project) => {
            setProject(updatedProject);
            setLoading(false);
            setShow(false);
        }).catch(() => {
            setFormError(intl.formatMessage({id: "errorUpdatingProject"}));
            setLoading(false);
        });
    }, [loading]);

    return (
        <AlertModal
            type={"info"}
            loading={loading}
            show={show}
            onClose={() => setShow(false)}
            onContinue={handleSubmit(() => {
                setLoading(true)
            })}
            title={intl.formatMessage({id: "editProject"})}
            cancelText={intl.formatMessage({id: "cancel"})}
            continueText={intl.formatMessage({id: "save"})}
        >
            <div className={"mb-6 space-y-6"}>
                <AlertText>
                    <FormattedMessage id="updateProjectDescription"/>
                </AlertText>

                <Form
                    onSubmit={handleSubmit(() => {
                        setLoading(true)
                    })}
                    error={formError}
                >
                    <ProjectNameField
                        fieldName={"name"}
                        register={register}
                        fieldError={errors.name}
                        disabled={loading}
                    />
                </Form>
            </div>
        </AlertModal>
    );
};

export default EditProjectModal;