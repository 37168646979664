import React, {useEffect, useMemo, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {useForm} from "react-hook-form";
import {AlertModal, AlertText, Form, InputField, NotificationService} from "peggirkit";
import {addKeywords, getUsageData, UsageData, User} from "serpotrack-connector";

type Props = {
    show: boolean,
    setShow: (show: boolean) => void,
    projectId: number,
    user: User,
    setKeywordsOverviewLastUpdated: (date: Date) => void
};

type FormValues = {
    keywords: string
};

const AddKeywordsModal = ({show, setShow, projectId, user, setKeywordsOverviewLastUpdated}: Props) => {
    const intl = useIntl();
    const {register, getValues, handleSubmit, reset, formState: {errors}} = useForm<FormValues>();
    const [loading, setLoading] = useState(false);
    const [formError, setFormError] = useState<string | null>(null);
    const [totalUserKeywords, setTotalUserKeywords] = useState<number | null>(null);
    const [keywordsInInput, setKeywordsInInput] = useState(0);
    const keywordLimit = useMemo(() => {
        return user.subscriptions.length === 0
            ? 0
            : user.subscriptions[0].plan.keywordLimit;
    }, [user]);

    useEffect(() => {
        if (!show) {
            return;
        }

        getUsageData().then((data: UsageData) => {
            setTotalUserKeywords(data.keywords);
        });
    }, [show]);

    useEffect(() => {
        if (!loading) {
            return;
        }

        const newKeywords = getValues()
            .keywords
            .split("\n")
            .filter(k => k.trim().length !== 0 && k.length <= 500)
            .map(k => ({keyword: k}));
        addKeywords(projectId, newKeywords).then(() => {
            setKeywordsOverviewLastUpdated(new Date());
            setShow(false);
            setLoading(false);
            setFormError(null);
            setKeywordsInInput(0);
            NotificationService.notify({type: "success", text: intl.formatMessage({id: "keywordsAddedToProject"})});
            reset();
        }).catch(e => {
            setFormError(intl.formatMessage({
                id: e.response.status === 412 ? "keywordLimitReached" : "errorAddingKeywords"
            }));
            setLoading(false);
        });
    }, [loading]);

    const validateKeywords = (val: string): string | undefined => {
        const ks = val.split("\n");
        if (ks.length > keywordLimit) {
            const availableKeywords = keywordLimit - (totalUserKeywords || 0);
            if (availableKeywords <= 0) {
                return intl.formatMessage({id: "keywordLimitReached"});
            }

            return intl.formatMessage({id: "youCanOnlyAddAvailableKeywords"},
                {availableKeywords: availableKeywords});
        }

        for (let i = 0; i < ks.length; i++) {
            const k = ks[i];
            if (k.length > 500) {
                return intl.formatMessage({id: "keywordSizeExceeded"});
            }
            if (k.trim() === "") {
                return intl.formatMessage({id: "keywordCannotBeEmpty"});
            }
        }
    }

    return (
        <AlertModal
            type={"info"}
            loading={loading}
            show={show}
            onClose={() => setShow(false)}
            onContinue={handleSubmit(() => setLoading(true))}
            title={intl.formatMessage({id: "addKeywords"})}
            cancelText={intl.formatMessage({id: "cancel"})}
            continueText={intl.formatMessage({id: "ok"})}
        >
            <div className={"mb-6 space-y-6"}>
                <AlertText>
                    <FormattedMessage id={"addKeywordsDescription"}/>
                </AlertText>
                <Form
                    onSubmit={handleSubmit(() => setLoading(true))}
                    error={formError}
                >
                    <InputField
                        type={"textarea"}
                        displayName={intl.formatMessage({id: "keywords"})}
                        id={"keywords"}
                        placeholder={intl.formatMessage({id: "addKeywordsPlaceholder"})}
                        reactHookForm={{
                            ...register("keywords", {
                                required: intl.formatMessage({id: "enterAtLeastOneKeyword"}),
                                onChange: e => setKeywordsInInput(e.target.value.split("\n").length),
                                validate: validateKeywords
                            })
                        }}
                        disabled={loading}
                        error={errors.keywords && errors.keywords.message}
                        tip={intl.formatMessage({id: "addKeywordsTip"}, {
                            currentKeywords: (totalUserKeywords || 0) + keywordsInInput,
                            keywordLimit: keywordLimit
                        })}
                        textareaHeight={"h-60"}
                    />
                </Form>
            </div>
        </AlertModal>
    );
};

export default AddKeywordsModal;