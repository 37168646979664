import React, {useEffect, useState} from "react";
import {useIntl} from "react-intl";
import {Stats, TextSkeleton} from "peggirkit";
import {KeywordPosition} from "serpotrack-connector";

type Props = {
    positions: KeywordPosition[]
};

const statPlaceHolder = <TextSkeleton width={"max-w-[50px]"} pulse={false}/>;

const StatsSection = ({positions}: Props) => {
    const intl = useIntl();
    const [avgPos, setAvgPos] = useState<JSX.Element | number>(statPlaceHolder);
    const [bestPos, setBestPos] = useState<JSX.Element | number>(statPlaceHolder);
    const [worstPos, setWorstPos] = useState<JSX.Element | number>(statPlaceHolder);

    useEffect(() => {
        if (positions.length === 0) {
            setAvgPos(statPlaceHolder);
            setBestPos(statPlaceHolder);
            setWorstPos(statPlaceHolder);
            return;
        }

        let best = positions[0].rank;
        let worst = positions[0].rank;
        let sum = 0;
        positions.map(p => {
            const rank = p.rank;
            if (rank < best) {
                best = rank;
            }

            if (rank > worst) {
                worst = rank;
            }

            sum += rank;
        });

        setAvgPos(Math.round(sum / positions.length));
        setBestPos(best);
        setWorstPos(worst);
    }, [positions]);

    return (
        <Stats
            items={[
                {name: intl.formatMessage({id: "avgPosition"}), stat: avgPos},
                {name: intl.formatMessage({id: "bestPosition"}), stat: bestPos},
                {name: intl.formatMessage({id: "worstPosition"}), stat: worstPos},
            ]}
        />
    );
};

export default StatsSection;