import React, {useEffect, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {formatMoney} from "./MoneyUtils";
import {Alert, Button, DescriptionList, Select, Tooltip} from "peggirkit";

export type PlanSpec = {
    projects: number,
    keywords: number,
    maxPosition: number,
    history: number,
    updateInterval: number,
    endDate?: string
};

export type PlanInstance = {
    disabled: boolean,
    setDisabled: (prevState: boolean) => void
};

type Props = {
    title: string,
    description: string,
    onClick: (planSpec: PlanSpec) => void,
    onChange?: (planSpec: PlanSpec) => void,
    actionText: string,
    actionType?: "primary" | "danger",
    dangerAlertText?: string,
    initialPlan: PlanSpec,
    composeEnabled: boolean,
    disabled: boolean,
    price?: number,
    loading: boolean,
    cancelled?: boolean
};

const Plan = ({
                  title,
                  description,
                  onClick,
                  actionText,
                  actionType = "primary",
                  dangerAlertText,
                  initialPlan,
                  composeEnabled,
                  price,
                  onChange,
                  disabled,
                  loading,
                  cancelled
              }: Props) => {
    const intl = useIntl();
    const [planSpec, setPlanSpec] = useState<PlanSpec>(initialPlan);

    useEffect(() => {
        if (onChange) {
            onChange(planSpec);
        }
    }, [planSpec]);

    const displayedProjects = (projects: number): string | JSX.Element => {
        return projects === -1 ? intl.formatMessage({id: "unlimited"}) : projects + "";
    }

    const displayedHistory = (history: number): string | JSX.Element => {
        return history === -1 ? intl.formatMessage({id: "all"}) : intl.formatMessage({id: "nDays"}, {days: history});
    }

    const displayedKeywords = (keywords: number): string | JSX.Element => {
        if (!composeEnabled) {
            return keywords + "";
        }

        return <Select
            id={"keywords"}
            displayName={intl.formatMessage({id: "keywords"})}
            options={[...Array(20)].map((_, i) => ({value: (i + 1) * 5, label: (i + 1) * 5}))}
            defaultValue={5}
            disabled={disabled}
            onChange={e => setPlanSpec({...planSpec, keywords: parseInt(e.target.value)})}
        />
    }

    const displayedMaxPosition = (maxPosition: number): string | JSX.Element => {
        if (!composeEnabled) {
            return maxPosition + "";
        }

        return <Select
            id={"maxPosition"}
            displayName={intl.formatMessage({id: "maxPosition"})}
            options={[
                {value: 50, label: "50"},
                {value: 100, label: "100"}
            ]}
            defaultValue={50}
            disabled={disabled}
            onChange={e => setPlanSpec({...planSpec, maxPosition: parseInt(e.target.value)})}
        />
    }

    const displayedUpdateInterval = (updateInterval: number): string | JSX.Element => {
        const intervalOptions = [
            {value: 24, label: `24 ${intl.formatMessage({id: "hours"})}`},
            {value: 48, label: `48 ${intl.formatMessage({id: "hours"})}`},
            {value: 168, label: `7 ${intl.formatMessage({id: "days"})}`}
        ];

        if (!composeEnabled) {
            return intervalOptions.filter(o => o.value === updateInterval)[0].label;
        }

        return <Select
            id={"updateInterval"}
            displayName={intl.formatMessage({id: "updateInterval"})}
            options={intervalOptions}
            defaultValue={updateInterval}
            disabled={disabled}
            onChange={e => setPlanSpec({...planSpec, updateInterval: parseInt(e.target.value)})}
        />
    }

    return (
        <div className="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow w-full max-w-md">
            <div className="bg-white px-4 py-5 sm:px-6">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                    {title}
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                    {description}
                </p>
            </div>
            <div className="px-4 py-5 sm:p-6">
                <DescriptionList
                    skipFirstBorder={true}
                    items={[
                        {
                            key: intl.formatMessage({id: "projects"}),
                            keyTooltip: intl.formatMessage({id: "subscription.projects.tooltip"}),
                            value: displayedProjects(planSpec.projects)
                        },
                        {
                            key: intl.formatMessage({id: "keywords"}),
                            keyTooltip: intl.formatMessage({id: "subscription.keywords.tooltip"}),
                            value: displayedKeywords(planSpec.keywords)
                        },
                        {
                            key: intl.formatMessage({id: "maxPosition"}),
                            keyTooltip: intl.formatMessage({id: "subscription.maxPosition.tooltip"}),
                            value: displayedMaxPosition(planSpec.maxPosition)
                        },
                        {
                            key: intl.formatMessage({id: "updateInterval"}),
                            keyTooltip: intl.formatMessage({id: "subscription.updateInterval.tooltip"}),
                            value: displayedUpdateInterval(planSpec.updateInterval)
                        },
                        {
                            key: intl.formatMessage({id: "history"}),
                            keyTooltip: intl.formatMessage({id: "subscription.history.tooltip"}),
                            value: displayedHistory(planSpec.history)
                        },
                        ...(
                            planSpec.endDate ? [{
                                key: intl.formatMessage({id: cancelled ? "endsOn" : "renewsOn"}),
                                value: new Date(planSpec.endDate).toLocaleDateString()
                            }] : []
                        )
                    ]}
                />
            </div>
            <div className="px-4 py-4 sm:px-6">
                {
                    dangerAlertText &&
                    <Alert
                        type={"danger"}
                        title={dangerAlertText}
                        className={"mb-3"}
                    />
                }
                <div className={"flex flex-row-reverse items-end justify-between"}>
                    {
                        (cancelled === undefined || !cancelled) &&
                        <Button
                            type={actionType}
                            onClick={() => {
                                onClick(planSpec);
                            }}
                            disabled={disabled}
                            text={actionText}
                            loading={loading}
                        />
                    }
                    {
                        (price !== undefined) &&
                        <div className={"flex flex-col"}>
                            <p className={"font-semibold text-xl"}>
                                {formatMoney(price)}
                            </p>
                            <p className="text-xs text-gray-500">
                                <FormattedMessage id="exDutchTax"/>
                            </p>
                        </div>
                    }
                </div>
            </div>
            <Tooltip/>
        </div>
    );
};

export default Plan;