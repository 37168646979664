import React, {useEffect, useState} from "react";
import {DateRange} from "react-day-picker";
import {useIntl} from "react-intl";
import {addDays, addMonths, endOfDay, startOfDay} from "date-fns";
import {CurrencyDollarIcon} from "@heroicons/react/24/outline";
import {getHistoryFromDate} from "./HistoryLimit";
import {RangePicker} from "peggirkit";
import {User} from "serpotrack-connector";

export type ProjectDateRange = {
    displayName: string,
    showPicker: boolean,
    dateRange: DateRange
};

type Props = {
    user: User,
    dateRange: ProjectDateRange,
    setDateRange: (dateRange: ProjectDateRange) => void
};

type RangePreset = {
    value: number,
    enabled: boolean
};

const DayRangeSelector = ({user, dateRange, setDateRange}: Props) => {
    const intl = useIntl();
    const [rangePresetDays, setRangePresetDays] = useState<RangePreset[]>([]);
    const hasSubscription = user.subscriptions.length > 0;
    const historyLimit: number | null = hasSubscription ? user.subscriptions[0].plan.historyLimit : null;
    const [rangePresetMonths, setRangePresetMonths] = useState<RangePreset[]>([]);

    useEffect(() => {
        setRangePresetDays([
            {
                value: 7,
                enabled: true
            },
            {
                value: 31,
                enabled: true // All (free) can view history of 31 days
            },
            {
                value: 90,
                enabled: historyLimit !== null ? (historyLimit === -1 || historyLimit >= 90) : false
            },
        ]);
        setRangePresetMonths([
            {
                value: 12,
                enabled: historyLimit !== null ? (historyLimit === -1 || historyLimit >= 365) : false
            },
            {
                value: 24,
                enabled: historyLimit !== null ? (historyLimit === -1 || historyLimit >= 730) : false
            },
        ]);
    }, []);

    return (
        <RangePicker
            title={intl.formatMessage({id: "pickARange"})}
            cancelText={intl.formatMessage({id: "cancel"})}
            continueText={intl.formatMessage({id: "apply"})}
            show={dateRange.showPicker}
            setShow={(show: boolean) => setDateRange({...dateRange, showPicker: show})}
            defaultSelected={dateRange.dateRange}
            onSelect={(range?: DateRange, name?: string) => {
                if (!range || !range.from || !range.to) {
                    return;
                }

                const newRange = dateRange;
                dateRange.dateRange.from = startOfDay(range.from);
                dateRange.dateRange.to = endOfDay(range.to);
                dateRange.displayName = name || intl.formatMessage({id: "customRange"});
                setDateRange(newRange);
            }}
            rangePresets={[
                ...rangePresetDays.map(({value, enabled}) => ({
                    name: intl.formatMessage({id: "lastNDays"}, {days: value}),
                    range: {
                        from: startOfDay(addDays(new Date(), -value)),
                        to: endOfDay(new Date())
                    },
                    disabled: !enabled,
                    icon: !enabled ? CurrencyDollarIcon : undefined
                })),
                ...rangePresetMonths.map(({value, enabled}) => ({
                    name: intl.formatMessage({id: "lastNMonths"}, {months: value}),
                    range: {
                        from: startOfDay(addMonths(new Date(), -value)),
                        to: endOfDay(new Date())
                    },
                    disabled: !enabled,
                    icon: !enabled ? CurrencyDollarIcon : undefined
                }))
            ]}
            fromDate={getHistoryFromDate(historyLimit)}
            toDate={new Date()}
        />
    );
};

export default DayRangeSelector;