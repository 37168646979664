import React, {useEffect, useState} from "react";
import {useIntl} from "react-intl";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import KeywordsDataTable from "../../widgets/project/KeywordsDataTable";
import PageHeadingProject from "../../widgets/project/PageHeadingProject";
import KeywordDetailsPanel from "../../widgets/project/keywordDetails/KeywordDetailsPanel";
import KeywordDetailsSlideOver from "../../widgets/project/keywordDetails/KeywordDetailsSlideOver";
import {addDays, endOfDay, startOfDay} from "date-fns";
import DayRangeSelector, {ProjectDateRange} from "../../widgets/project/DayRangeSelector";
import DeleteProjectModal from "../../widgets/project/DeleteProjectModal";
import AddKeywordsModal from "../../widgets/project/AddKeywordsModal";
import {classNames} from "peggirkit";
import SerpotrackFrame from "./layout/SerpotrackFrame";
import {getCurrentUser, getProject, Keyword, Project, User} from "serpotrack-connector";
import EditProjectModal from "../../widgets/project/EditProjectModal";

export type SetKeywordDetailsToShow = {
    setKeywordDetailsToShow: (keyword: Keyword | null) => void
};

export type SetKeywordsOverviewLastUpdated = {
    setKeywordsOverviewLastUpdated: (date: Date) => void
};

const ProjectPage = () => {
    const intl = useIntl();
    const {state} = useLocation();
    const navigate = useNavigate();
    const {projectId} = useParams();
    const [currentUser, setCurrentUser] = useState<User | null>(null);
    const [project, setProject] = useState<Project | null>(state && state.project);
    const [keywordDetailsToShow, setKeywordDetailsToShow] = useState<Keyword | null>(null);
    const [keywordDetailsSliderOpen, setKeywordDetailsSliderOpen] = useState(false);
    const [isMobile, setMobile] = useState(false);
    const [showEditDialog, setShowEditDialog] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [showAddKeywordsDialog, setShowAddKeywordsDialog] = useState(false);
    const [keywordsOverviewLastUpdated, setKeywordsOverviewLastUpdated] = useState(new Date());
    const [dateRange, setDateRange] = useState<ProjectDateRange>({
        displayName: intl.formatMessage({id: "lastNDays"}, {days: 31}),
        showPicker: false,
        dateRange: {
            from: startOfDay(addDays(new Date(), -31)),
            to: endOfDay(new Date())
        }
    });

    // Handle viewport changes
    useEffect(() => {
        const resizeHandler = () => setMobile(window.innerWidth < 1200);
        window.addEventListener("resize", resizeHandler);
        resizeHandler();
        return () => window.removeEventListener("resize", resizeHandler);
    }, []);

    // Load project
    useEffect(() => {
        if (projectId === undefined) {
            navigate("/404");
            return;
        }

        getCurrentUser().then(user => setCurrentUser(user));

        if (project !== null) {
            return;
        }

        getProject(parseInt(projectId))
            .then((p: Project) => setProject(p))
            .catch(_ => navigate("/404"));
    }, []);

    // Mobile slide-over effect
    useEffect(() => {
        setKeywordDetailsSliderOpen(keywordDetailsToShow !== null);

        if (!isMobile && keywordDetailsToShow !== null) {
            window.scrollTo(0, 0);
        }
    }, [keywordDetailsToShow]);

    return (
        <SerpotrackFrame
            title={`${project?.name || intl.formatMessage({id: "project"})} - ${intl.formatMessage({id: "positionTracking"})}`}
        >
            <PageHeadingProject
                projectId={projectId === undefined ? undefined : parseInt(projectId)}
                user={currentUser}
                project={project}
                dateRange={dateRange}
                setDateRange={setDateRange}
                setShowDeleteDialog={setShowDeleteDialog}
                setShowAddKeywordsDialog={setShowAddKeywordsDialog}
                setShowEditProject={setShowEditDialog}
            />

            <div className={"w-full flex gap-8 mt-8 md:mt-16 items-start"}>
                <div className={classNames((keywordDetailsToShow !== null && !isMobile) ? "w-1/2" : "w-full")}>
                    <KeywordsDataTable
                        currentUser={currentUser}
                        projectId={parseInt(projectId as string)}
                        keywordDetailsToShow={keywordDetailsToShow}
                        setKeywordDetailsToShow={setKeywordDetailsToShow}
                        dateRange={dateRange.dateRange}
                        setShowAddKeywordsDialog={setShowAddKeywordsDialog}
                        lastUpdated={keywordsOverviewLastUpdated}
                        setLastUpdated={setKeywordsOverviewLastUpdated}
                    />
                </div>
                {
                    (keywordDetailsToShow !== null && !isMobile) &&
                    <div className={"w-1/2"}>
                        <KeywordDetailsPanel
                            currentUser={currentUser}
                            projectId={parseInt(projectId as string)}
                            keyword={keywordDetailsToShow}
                            setKeywordDetailsToShow={setKeywordDetailsToShow}
                            dateRange={dateRange}
                            setKeywordsOverviewLastUpdated={setKeywordsOverviewLastUpdated}
                        />
                    </div>
                }
            </div>

            {
                isMobile
                    ? <KeywordDetailsSlideOver
                        currentUser={currentUser}
                        projectId={parseInt(projectId as string)}
                        keyword={keywordDetailsToShow}
                        setKeywordDetailsToShow={setKeywordDetailsToShow}
                        open={keywordDetailsSliderOpen}
                        setOpen={setKeywordDetailsSliderOpen}
                        dateRange={dateRange}
                        setKeywordsOverviewLastUpdated={setKeywordsOverviewLastUpdated}
                    />
                    : <></>
            }

            {
                currentUser !== null
                    ? <>
                        <DayRangeSelector
                            user={currentUser}
                            dateRange={dateRange}
                            setDateRange={setDateRange}
                        />
                        <AddKeywordsModal
                            user={currentUser}
                            show={showAddKeywordsDialog}
                            setShow={setShowAddKeywordsDialog}
                            projectId={parseInt(projectId as string)}
                            setKeywordsOverviewLastUpdated={setKeywordsOverviewLastUpdated}
                        />
                    </>
                    : <></>
            }

            <DeleteProjectModal
                show={showDeleteDialog}
                setShow={setShowDeleteDialog}
                projectId={parseInt(projectId as string)}
            />

            {
                project !== null
                    ? <EditProjectModal
                        show={showEditDialog}
                        setShow={setShowEditDialog}
                        setProject={setProject}
                        project={project}
                    />
                    : <></>
            }
        </SerpotrackFrame>
    );
};

export default ProjectPage;