import React, {useMemo} from "react";
import {CartesianGrid, Line, LineChart, Tooltip, XAxis, YAxis} from "recharts";
import PositionChartTooltip from "./PositionChartTooltip";
import {useIntl} from "react-intl";
import ResponsiveContainer from "./ResponsiveContainer";
import PositionChartEmptyState from "./PositionChartEmptyState";
import {KeywordPosition} from "serpotrack-connector";

type Props = {
    positions: KeywordPosition[]
};

const axisStyling = {
    fontSize: "0.75rem",
    fill: "rgb(107 114 128)"
};

const PositionChart = ({positions}: Props) => {
    const intl = useIntl();
    const formattedPositions = useMemo(() => (
        positions.map((p: KeywordPosition) => ({
            date: new Date(p.date).toLocaleDateString(),
            rank: p.rank
        }))
    ), [positions]);

    if (formattedPositions.length === 0) {
        return <PositionChartEmptyState/>;
    }

    return (
        <ResponsiveContainer>
            <LineChart
                data={formattedPositions}
                margin={{top: 0, right: 5, bottom: 0, left: 5}}
            >
                <XAxis
                    tick={axisStyling}
                    tickMargin={12}
                    tickLine={false}
                    strokeWidth={3}
                    stroke={"#e5e7eb"}
                    dataKey="date"
                />
                <YAxis
                    tick={axisStyling}
                    tickMargin={12}
                    width={37}
                    tickLine={false}
                    axisLine={false}
                    reversed={true}
                    allowDecimals={false}
                />
                <CartesianGrid
                    stroke="#e5e7eb"
                    strokeDasharray="5 5"
                />
                <Line
                    type="monotone"
                    dataKey="rank"
                    name={intl.formatMessage({id: "rank"})}
                    stroke="#1d4ed8"
                    strokeWidth={3}
                    dot={positions.length <= 33}
                />
                <Tooltip content={PositionChartTooltip}/>
            </LineChart>
        </ResponsiveContainer>
    );
};

export default PositionChart;