import React from "react";
import {useIntl} from "react-intl";
import PositionChart from "./positionChart/PositionChart";
import {SectionHeading} from "peggirkit";
import {KeywordPosition} from "serpotrack-connector";

type Props = {
    positions: KeywordPosition[]
};

const PositionHistorySection = ({positions}: Props) => {
    const intl = useIntl();

    return (
        <div>
            <SectionHeading
                title={intl.formatMessage({id: "positionHistory"})}
                description={intl.formatMessage({id: "positionHistory.description"})}
                className={"mb-4"}
            />

            <PositionChart positions={positions}/>
        </div>
    );
};

export default PositionHistorySection;