import React, {useState} from "react";
import Plan, {PlanInstance, PlanSpec} from "./Plan";
import {useIntl} from "react-intl";
import {FirstPayment, getFirstPaymentCheckoutUrl} from "serpotrack-connector";

const PlanCustom = ({disabled, setDisabled}: PlanInstance) => {
    const intl = useIntl();
    const [customPrice, setCustomPrice] = useState(130);
    const [loading, setLoading] = useState(false);

    const updateCustomPrice = (planSpec: PlanSpec): void => {
        const mapToInterval = [];
        mapToInterval[24] = 31;
        mapToInterval[48] = 16;
        mapToInterval[168] = 4;

        const priceMonthlyExTaxThousandsCents = 1000 +
            (3 * planSpec.keywords * (planSpec.maxPosition / 10)
                * mapToInterval[planSpec.updateInterval]);
        const cents = priceMonthlyExTaxThousandsCents / 10.0;
        setCustomPrice(cents);
    }

    const subscribeToPlan = (planSpec: PlanSpec): void => {
        if (loading) {
            return;
        }

        setDisabled(true);
        setLoading(true);
        getFirstPaymentCheckoutUrl(
            planSpec.keywords,
            planSpec.updateInterval,
            planSpec.maxPosition
        ).then((res: FirstPayment) => {
            window.location.replace(res.checkoutUrl);
        });
    }

    return (
        <Plan
            title={intl.formatMessage({id: "customPlan"})}
            description={intl.formatMessage({id: "composeCustomPlanDescription"})}
            actionText={intl.formatMessage({id: "subscribeMonthly"})}
            onClick={subscribeToPlan}
            onChange={updateCustomPrice}
            initialPlan={{
                projects: -1,
                history: -1,
                keywords: 5,
                maxPosition: 50,
                updateInterval: 168,
            }}
            composeEnabled={true}
            price={customPrice}
            disabled={disabled}
            loading={loading}
        />
    );
};

export default PlanCustom;