import React, {useEffect, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {AlertModal, AlertText, NotificationService} from "peggirkit";
import {MfaBackupCode} from "serpotrack-connector";
import {regenerateBackupCodes} from "serpotrack-connector/dist/Serpotrack";
import BackupCodesModal from "./BackupCodesModal";

type Props = {
    show: boolean,
    setShow: (show: boolean) => void
};

const GenerateBackupCodesModal = ({show, setShow}: Props) => {
    const intl = useIntl();
    const [loading, setLoading] = useState(false);
    const [showBackupCodesModal, setShowBackupCodesModal] = useState(false);
    const [backupCodes, setBackupCodes] = useState<MfaBackupCode[]>([]);

    // Generate new codes
    useEffect(() => {
        if (!loading) {
            return;
        }

        regenerateBackupCodes()
            .then((e: MfaBackupCode[]) => {
                setBackupCodes(e);
                setLoading(false);
            })
            .catch(_ => {
                NotificationService.notify({type: "danger", text: intl.formatMessage({id: "unexpectedServerError"})});
                setLoading(false);
                setShow(false);
            });
    }, [loading]);

    // Show backup codes modal
    useEffect(() => {
        if (backupCodes.length === 0) {
            return;
        }

        setShow(false);
        setShowBackupCodesModal(true);
    }, [backupCodes]);

    return (
        <>
            <AlertModal
                type="info"
                show={show}
                hideIcon={false}
                onClose={() => setShow(false)}
                onContinue={() => setLoading(true)}
                title={intl.formatMessage({id: "confirmNewBackupCode"})}
                cancelText={intl.formatMessage({id: "cancel"})}
                continueText={intl.formatMessage({id: "confirm"})}
                loading={loading}
            >
                <AlertText>
                    <FormattedMessage id={"confirmGenerateBackupCodes"}/>
                </AlertText>
            </AlertModal>

            <BackupCodesModal
                show={showBackupCodesModal}
                close={() => setShowBackupCodesModal(false)}
                codes={backupCodes}
            />
        </>
    );
};

export default GenerateBackupCodesModal;