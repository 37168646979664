import React from "react";
import {useIntl} from "react-intl";
import router from "../../../routing/Router";
import {Error as AuthErrorPage} from "serpotrack-frontend-authentication";

const Error = () => {
    const intl = useIntl();
    return (
        <AuthErrorPage
            links={[
                {
                    text: intl.formatMessage({id: "dashboard"}),
                    href: router.projects.absolutePath()
                }
            ]}
        />
    );
};

export default Error;