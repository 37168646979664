export const countryCodeOptions = [
    {
        "value": "AF",
        "label": "AF (Afghanistan)"
    },
    {
        "value": "AX",
        "label": "AX (Åland Islands)"
    },
    {
        "value": "AL",
        "label": "AL (Albania)"
    },
    {
        "value": "DZ",
        "label": "DZ (Algeria)"
    },
    {
        "value": "AS",
        "label": "AS (American Samoa)"
    },
    {
        "value": "AD",
        "label": "AD (Andorra)"
    },
    {
        "value": "AO",
        "label": "AO (Angola)"
    },
    {
        "value": "AI",
        "label": "AI (Anguilla)"
    },
    {
        "value": "AQ",
        "label": "AQ (Antarctica)"
    },
    {
        "value": "AG",
        "label": "AG (Antigua and Barbuda)"
    },
    {
        "value": "AR",
        "label": "AR (Argentina)"
    },
    {
        "value": "AM",
        "label": "AM (Armenia)"
    },
    {
        "value": "AW",
        "label": "AW (Aruba)"
    },
    {
        "value": "AU",
        "label": "AU (Australia)"
    },
    {
        "value": "AT",
        "label": "AT (Austria)"
    },
    {
        "value": "AZ",
        "label": "AZ (Azerbaijan)"
    },
    {
        "value": "BS",
        "label": "BS (Bahamas)"
    },
    {
        "value": "BH",
        "label": "BH (Bahrain)"
    },
    {
        "value": "BD",
        "label": "BD (Bangladesh)"
    },
    {
        "value": "BB",
        "label": "BB (Barbados)"
    },
    {
        "value": "BY",
        "label": "BY (Belarus)"
    },
    {
        "value": "BE",
        "label": "BE (Belgium)"
    },
    {
        "value": "BZ",
        "label": "BZ (Belize)"
    },
    {
        "value": "BJ",
        "label": "BJ (Benin)"
    },
    {
        "value": "BM",
        "label": "BM (Bermuda)"
    },
    {
        "value": "BT",
        "label": "BT (Bhutan)"
    },
    {
        "value": "BO",
        "label": "BO (Bolivia, Plurinational State of)"
    },
    {
        "value": "BQ",
        "label": "BQ (Bonaire, Sint Eustatius and Saba)"
    },
    {
        "value": "BA",
        "label": "BA (Bosnia and Herzegovina)"
    },
    {
        "value": "BW",
        "label": "BW (Botswana)"
    },
    {
        "value": "BV",
        "label": "BV (Bouvet Island)"
    },
    {
        "value": "BR",
        "label": "BR (Brazil)"
    },
    {
        "value": "IO",
        "label": "IO (British Indian Ocean Territory)"
    },
    {
        "value": "BN",
        "label": "BN (Brunei Darussalam)"
    },
    {
        "value": "BG",
        "label": "BG (Bulgaria)"
    },
    {
        "value": "BF",
        "label": "BF (Burkina Faso)"
    },
    {
        "value": "BI",
        "label": "BI (Burundi)"
    },
    {
        "value": "KH",
        "label": "KH (Cambodia)"
    },
    {
        "value": "CM",
        "label": "CM (Cameroon)"
    },
    {
        "value": "CA",
        "label": "CA (Canada)"
    },
    {
        "value": "CV",
        "label": "CV (Cape Verde)"
    },
    {
        "value": "KY",
        "label": "KY (Cayman Islands)"
    },
    {
        "value": "CF",
        "label": "CF (Central African Republic)"
    },
    {
        "value": "TD",
        "label": "TD (Chad)"
    },
    {
        "value": "CL",
        "label": "CL (Chile)"
    },
    {
        "value": "CN",
        "label": "CN (China)"
    },
    {
        "value": "CX",
        "label": "CX (Christmas Island)"
    },
    {
        "value": "CC",
        "label": "CC (Cocos (Keeling) Islands)"
    },
    {
        "value": "CO",
        "label": "CO (Colombia)"
    },
    {
        "value": "KM",
        "label": "KM (Comoros)"
    },
    {
        "value": "CG",
        "label": "CG (Congo)"
    },
    {
        "value": "CD",
        "label": "CD (Congo, the Democratic Republic of the)"
    },
    {
        "value": "CK",
        "label": "CK (Cook Islands)"
    },
    {
        "value": "CR",
        "label": "CR (Costa Rica)"
    },
    {
        "value": "CI",
        "label": "CI (Côte d'Ivoire)"
    },
    {
        "value": "HR",
        "label": "HR (Croatia)"
    },
    {
        "value": "CU",
        "label": "CU (Cuba)"
    },
    {
        "value": "CW",
        "label": "CW (Curaçao)"
    },
    {
        "value": "CY",
        "label": "CY (Cyprus)"
    },
    {
        "value": "CZ",
        "label": "CZ (Czech Republic)"
    },
    {
        "value": "DK",
        "label": "DK (Denmark)"
    },
    {
        "value": "DJ",
        "label": "DJ (Djibouti)"
    },
    {
        "value": "DM",
        "label": "DM (Dominica)"
    },
    {
        "value": "DO",
        "label": "DO (Dominican Republic)"
    },
    {
        "value": "EC",
        "label": "EC (Ecuador)"
    },
    {
        "value": "EG",
        "label": "EG (Egypt)"
    },
    {
        "value": "SV",
        "label": "SV (El Salvador)"
    },
    {
        "value": "GQ",
        "label": "GQ (Equatorial Guinea)"
    },
    {
        "value": "ER",
        "label": "ER (Eritrea)"
    },
    {
        "value": "EE",
        "label": "EE (Estonia)"
    },
    {
        "value": "ET",
        "label": "ET (Ethiopia)"
    },
    {
        "value": "FK",
        "label": "FK (Falkland Islands (Malvinas))"
    },
    {
        "value": "FO",
        "label": "FO (Faroe Islands)"
    },
    {
        "value": "FJ",
        "label": "FJ (Fiji)"
    },
    {
        "value": "FI",
        "label": "FI (Finland)"
    },
    {
        "value": "FR",
        "label": "FR (France)"
    },
    {
        "value": "GF",
        "label": "GF (French Guiana)"
    },
    {
        "value": "PF",
        "label": "PF (French Polynesia)"
    },
    {
        "value": "TF",
        "label": "TF (French Southern Territories)"
    },
    {
        "value": "GA",
        "label": "GA (Gabon)"
    },
    {
        "value": "GM",
        "label": "GM (Gambia)"
    },
    {
        "value": "GE",
        "label": "GE (Georgia)"
    },
    {
        "value": "DE",
        "label": "DE (Germany)"
    },
    {
        "value": "GH",
        "label": "GH (Ghana)"
    },
    {
        "value": "GI",
        "label": "GI (Gibraltar)"
    },
    {
        "value": "GR",
        "label": "GR (Greece)"
    },
    {
        "value": "GL",
        "label": "GL (Greenland)"
    },
    {
        "value": "GD",
        "label": "GD (Grenada)"
    },
    {
        "value": "GP",
        "label": "GP (Guadeloupe)"
    },
    {
        "value": "GU",
        "label": "GU (Guam)"
    },
    {
        "value": "GT",
        "label": "GT (Guatemala)"
    },
    {
        "value": "GG",
        "label": "GG (Guernsey)"
    },
    {
        "value": "GN",
        "label": "GN (Guinea)"
    },
    {
        "value": "GW",
        "label": "GW (Guinea-Bissau)"
    },
    {
        "value": "GY",
        "label": "GY (Guyana)"
    },
    {
        "value": "HT",
        "label": "HT (Haiti)"
    },
    {
        "value": "HM",
        "label": "HM (Heard Island and McDonald Islands)"
    },
    {
        "value": "VA",
        "label": "VA (Holy See (Vatican City State))"
    },
    {
        "value": "HN",
        "label": "HN (Honduras)"
    },
    {
        "value": "HK",
        "label": "HK (Hong Kong)"
    },
    {
        "value": "HU",
        "label": "HU (Hungary)"
    },
    {
        "value": "IS",
        "label": "IS (Iceland)"
    },
    {
        "value": "IN",
        "label": "IN (India)"
    },
    {
        "value": "ID",
        "label": "ID (Indonesia)"
    },
    {
        "value": "IR",
        "label": "IR (Iran, Islamic Republic of)"
    },
    {
        "value": "IQ",
        "label": "IQ (Iraq)"
    },
    {
        "value": "IE",
        "label": "IE (Ireland)"
    },
    {
        "value": "IM",
        "label": "IM (Isle of Man)"
    },
    {
        "value": "IL",
        "label": "IL (Israel)"
    },
    {
        "value": "IT",
        "label": "IT (Italy)"
    },
    {
        "value": "JM",
        "label": "JM (Jamaica)"
    },
    {
        "value": "JP",
        "label": "JP (Japan)"
    },
    {
        "value": "JE",
        "label": "JE (Jersey)"
    },
    {
        "value": "JO",
        "label": "JO (Jordan)"
    },
    {
        "value": "KZ",
        "label": "KZ (Kazakhstan)"
    },
    {
        "value": "KE",
        "label": "KE (Kenya)"
    },
    {
        "value": "KI",
        "label": "KI (Kiribati)"
    },
    {
        "value": "KP",
        "label": "KP (Korea, Democratic People's Republic of)"
    },
    {
        "value": "KR",
        "label": "KR (Korea, Republic of)"
    },
    {
        "value": "KW",
        "label": "KW (Kuwait)"
    },
    {
        "value": "KG",
        "label": "KG (Kyrgyzstan)"
    },
    {
        "value": "LA",
        "label": "LA (Lao People's Democratic Republic)"
    },
    {
        "value": "LV",
        "label": "LV (Latvia)"
    },
    {
        "value": "LB",
        "label": "LB (Lebanon)"
    },
    {
        "value": "LS",
        "label": "LS (Lesotho)"
    },
    {
        "value": "LR",
        "label": "LR (Liberia)"
    },
    {
        "value": "LY",
        "label": "LY (Libya)"
    },
    {
        "value": "LI",
        "label": "LI (Liechtenstein)"
    },
    {
        "value": "LT",
        "label": "LT (Lithuania)"
    },
    {
        "value": "LU",
        "label": "LU (Luxembourg)"
    },
    {
        "value": "MO",
        "label": "MO (Macao)"
    },
    {
        "value": "MK",
        "label": "MK (Macedonia, the Former Yugoslav Republic of)"
    },
    {
        "value": "MG",
        "label": "MG (Madagascar)"
    },
    {
        "value": "MW",
        "label": "MW (Malawi)"
    },
    {
        "value": "MY",
        "label": "MY (Malaysia)"
    },
    {
        "value": "MV",
        "label": "MV (Maldives)"
    },
    {
        "value": "ML",
        "label": "ML (Mali)"
    },
    {
        "value": "MT",
        "label": "MT (Malta)"
    },
    {
        "value": "MH",
        "label": "MH (Marshall Islands)"
    },
    {
        "value": "MQ",
        "label": "MQ (Martinique)"
    },
    {
        "value": "MR",
        "label": "MR (Mauritania)"
    },
    {
        "value": "MU",
        "label": "MU (Mauritius)"
    },
    {
        "value": "YT",
        "label": "YT (Mayotte)"
    },
    {
        "value": "MX",
        "label": "MX (Mexico)"
    },
    {
        "value": "FM",
        "label": "FM (Micronesia, Federated States of)"
    },
    {
        "value": "MD",
        "label": "MD (Moldova, Republic of)"
    },
    {
        "value": "MC",
        "label": "MC (Monaco)"
    },
    {
        "value": "MN",
        "label": "MN (Mongolia)"
    },
    {
        "value": "ME",
        "label": "ME (Montenegro)"
    },
    {
        "value": "MS",
        "label": "MS (Montserrat)"
    },
    {
        "value": "MA",
        "label": "MA (Morocco)"
    },
    {
        "value": "MZ",
        "label": "MZ (Mozambique)"
    },
    {
        "value": "MM",
        "label": "MM (Myanmar)"
    },
    {
        "value": "NA",
        "label": "NA (Namibia)"
    },
    {
        "value": "NR",
        "label": "NR (Nauru)"
    },
    {
        "value": "NP",
        "label": "NP (Nepal)"
    },
    {
        "value": "NL",
        "label": "NL (Netherlands)"
    },
    {
        "value": "NC",
        "label": "NC (New Caledonia)"
    },
    {
        "value": "NZ",
        "label": "NZ (New Zealand)"
    },
    {
        "value": "NI",
        "label": "NI (Nicaragua)"
    },
    {
        "value": "NE",
        "label": "NE (Niger)"
    },
    {
        "value": "NG",
        "label": "NG (Nigeria)"
    },
    {
        "value": "NU",
        "label": "NU (Niue)"
    },
    {
        "value": "NF",
        "label": "NF (Norfolk Island)"
    },
    {
        "value": "MP",
        "label": "MP (Northern Mariana Islands)"
    },
    {
        "value": "NO",
        "label": "NO (Norway)"
    },
    {
        "value": "OM",
        "label": "OM (Oman)"
    },
    {
        "value": "PK",
        "label": "PK (Pakistan)"
    },
    {
        "value": "PW",
        "label": "PW (Palau)"
    },
    {
        "value": "PS",
        "label": "PS (Palestine, State of)"
    },
    {
        "value": "PA",
        "label": "PA (Panama)"
    },
    {
        "value": "PG",
        "label": "PG (Papua New Guinea)"
    },
    {
        "value": "PY",
        "label": "PY (Paraguay)"
    },
    {
        "value": "PE",
        "label": "PE (Peru)"
    },
    {
        "value": "PH",
        "label": "PH (Philippines)"
    },
    {
        "value": "PN",
        "label": "PN (Pitcairn)"
    },
    {
        "value": "PL",
        "label": "PL (Poland)"
    },
    {
        "value": "PT",
        "label": "PT (Portugal)"
    },
    {
        "value": "PR",
        "label": "PR (Puerto Rico)"
    },
    {
        "value": "QA",
        "label": "QA (Qatar)"
    },
    {
        "value": "RE",
        "label": "RE (Réunion)"
    },
    {
        "value": "RO",
        "label": "RO (Romania)"
    },
    {
        "value": "RU",
        "label": "RU (Russian Federation)"
    },
    {
        "value": "RW",
        "label": "RW (Rwanda)"
    },
    {
        "value": "BL",
        "label": "BL (Saint Barthélemy)"
    },
    {
        "value": "SH",
        "label": "SH (Saint Helena, Ascension and Tristan da Cunha)"
    },
    {
        "value": "KN",
        "label": "KN (Saint Kitts and Nevis)"
    },
    {
        "value": "LC",
        "label": "LC (Saint Lucia)"
    },
    {
        "value": "MF",
        "label": "MF (Saint Martin (French part))"
    },
    {
        "value": "PM",
        "label": "PM (Saint Pierre and Miquelon)"
    },
    {
        "value": "VC",
        "label": "VC (Saint Vincent and the Grenadines)"
    },
    {
        "value": "WS",
        "label": "WS (Samoa)"
    },
    {
        "value": "SM",
        "label": "SM (San Marino)"
    },
    {
        "value": "ST",
        "label": "ST (Sao Tome and Principe)"
    },
    {
        "value": "SA",
        "label": "SA (Saudi Arabia)"
    },
    {
        "value": "SN",
        "label": "SN (Senegal)"
    },
    {
        "value": "RS",
        "label": "RS (Serbia)"
    },
    {
        "value": "SC",
        "label": "SC (Seychelles)"
    },
    {
        "value": "SL",
        "label": "SL (Sierra Leone)"
    },
    {
        "value": "SG",
        "label": "SG (Singapore)"
    },
    {
        "value": "SX",
        "label": "SX (Sint Maarten (Dutch part))"
    },
    {
        "value": "SK",
        "label": "SK (Slovakia)"
    },
    {
        "value": "SI",
        "label": "SI (Slovenia)"
    },
    {
        "value": "SB",
        "label": "SB (Solomon Islands)"
    },
    {
        "value": "SO",
        "label": "SO (Somalia)"
    },
    {
        "value": "ZA",
        "label": "ZA (South Africa)"
    },
    {
        "value": "GS",
        "label": "GS (South Georgia and the South Sandwich Islands)"
    },
    {
        "value": "SS",
        "label": "SS (South Sudan)"
    },
    {
        "value": "ES",
        "label": "ES (Spain)"
    },
    {
        "value": "LK",
        "label": "LK (Sri Lanka)"
    },
    {
        "value": "SD",
        "label": "SD (Sudan)"
    },
    {
        "value": "SR",
        "label": "SR (Suriname)"
    },
    {
        "value": "SJ",
        "label": "SJ (Svalbard and Jan Mayen)"
    },
    {
        "value": "SZ",
        "label": "SZ (Swaziland)"
    },
    {
        "value": "SE",
        "label": "SE (Sweden)"
    },
    {
        "value": "CH",
        "label": "CH (Switzerland)"
    },
    {
        "value": "SY",
        "label": "SY (Syrian Arab Republic)"
    },
    {
        "value": "TW",
        "label": "TW (Taiwan)"
    },
    {
        "value": "TJ",
        "label": "TJ (Tajikistan)"
    },
    {
        "value": "TZ",
        "label": "TZ (Tanzania, United Republic of)"
    },
    {
        "value": "TH",
        "label": "TH (Thailand)"
    },
    {
        "value": "TL",
        "label": "TL (Timor-Leste)"
    },
    {
        "value": "TG",
        "label": "TG (Togo)"
    },
    {
        "value": "TK",
        "label": "TK (Tokelau)"
    },
    {
        "value": "TO",
        "label": "TO (Tonga)"
    },
    {
        "value": "TT",
        "label": "TT (Trinidad and Tobago)"
    },
    {
        "value": "TN",
        "label": "TN (Tunisia)"
    },
    {
        "value": "TR",
        "label": "TR (Turkey)"
    },
    {
        "value": "TM",
        "label": "TM (Turkmenistan)"
    },
    {
        "value": "TC",
        "label": "TC (Turks and Caicos Islands)"
    },
    {
        "value": "TV",
        "label": "TV (Tuvalu)"
    },
    {
        "value": "UG",
        "label": "UG (Uganda)"
    },
    {
        "value": "UA",
        "label": "UA (Ukraine)"
    },
    {
        "value": "AE",
        "label": "AE (United Arab Emirates)"
    },
    {
        "value": "GB",
        "label": "GB (United Kingdom)"
    },
    {
        "value": "US",
        "label": "US (United States)"
    },
    {
        "value": "UM",
        "label": "UM (United States Minor Outlying Islands)"
    },
    {
        "value": "UY",
        "label": "UY (Uruguay)"
    },
    {
        "value": "UZ",
        "label": "UZ (Uzbekistan)"
    },
    {
        "value": "VU",
        "label": "VU (Vanuatu)"
    },
    {
        "value": "VE",
        "label": "VE (Venezuela, Bolivarian Republic of)"
    },
    {
        "value": "VN",
        "label": "VN (Viet Nam)"
    },
    {
        "value": "VG",
        "label": "VG (Virgin Islands, British)"
    },
    {
        "value": "VI",
        "label": "VI (Virgin Islands, U.S.)"
    },
    {
        "value": "WF",
        "label": "WF (Wallis and Futuna)"
    },
    {
        "value": "EH",
        "label": "EH (Western Sahara)"
    },
    {
        "value": "YE",
        "label": "YE (Yemen)"
    },
    {
        "value": "ZM",
        "label": "ZM (Zambia)"
    },
    {
        "value": "ZW",
        "label": "ZW (Zimbabwe)"
    }
];

export const languageOptions = [
    {
        "value": "af_NA",
        "label": "af_NA - Afrikaans (Namibia)"
    },
    {
        "value": "af_ZA",
        "label": "af_ZA - Afrikaans (South Africa)"
    },
    {
        "value": "ak_GH",
        "label": "ak_GH - Akan (Ghana)"
    },
    {
        "value": "sq_AL",
        "label": "sq_AL - Albanian (Albania)"
    },
    {
        "value": "am_ET",
        "label": "am_ET - Amharic (Ethiopia)"
    },
    {
        "value": "ar_DZ",
        "label": "ar_DZ - Arabic (Algeria)"
    },
    {
        "value": "ar_BH",
        "label": "ar_BH - Arabic (Bahrain)"
    },
    {
        "value": "ar_EG",
        "label": "ar_EG - Arabic (Egypt)"
    },
    {
        "value": "ar_IQ",
        "label": "ar_IQ - Arabic (Iraq)"
    },
    {
        "value": "ar_JO",
        "label": "ar_JO - Arabic (Jordan)"
    },
    {
        "value": "ar_KW",
        "label": "ar_KW - Arabic (Kuwait)"
    },
    {
        "value": "ar_LB",
        "label": "ar_LB - Arabic (Lebanon)"
    },
    {
        "value": "ar_LY",
        "label": "ar_LY - Arabic (Libya)"
    },
    {
        "value": "ar_MA",
        "label": "ar_MA - Arabic (Morocco)"
    },
    {
        "value": "ar_OM",
        "label": "ar_OM - Arabic (Oman)"
    },
    {
        "value": "ar_QA",
        "label": "ar_QA - Arabic (Qatar)"
    },
    {
        "value": "ar_SA",
        "label": "ar_SA - Arabic (Saudi Arabia)"
    },
    {
        "value": "ar_SD",
        "label": "ar_SD - Arabic (Sudan)"
    },
    {
        "value": "ar_SY",
        "label": "ar_SY - Arabic (Syria)"
    },
    {
        "value": "ar_TN",
        "label": "ar_TN - Arabic (Tunisia)"
    },
    {
        "value": "ar_AE",
        "label": "ar_AE - Arabic (United Arab Emirates)"
    },
    {
        "value": "ar_YE",
        "label": "ar_YE - Arabic (Yemen)"
    },
    {
        "value": "hy_AM",
        "label": "hy_AM - Armenian (Armenia)"
    },
    {
        "value": "as_IN",
        "label": "as_IN - Assamese (India)"
    },
    {
        "value": "bm_ML",
        "label": "bm_ML - Bambara (Mali)"
    },
    {
        "value": "eu_ES",
        "label": "eu_ES - Basque (Spain)"
    },
    {
        "value": "be_BY",
        "label": "be_BY - Belarusian (Belarus)"
    },
    {
        "value": "bn_BD",
        "label": "bn_BD - Bengali (Bangladesh)"
    },
    {
        "value": "bn_IN",
        "label": "bn_IN - Bengali (India)"
    },
    {
        "value": "bs_BA",
        "label": "bs_BA - Bosnian (Bosnia and Herzegovina)"
    },
    {
        "value": "bg_BG",
        "label": "bg_BG - Bulgarian (Bulgaria)"
    },
    {
        "value": "my_MM",
        "label": "my_MM - Burmese (Myanmar [Burma])"
    },
    {
        "value": "ca_ES",
        "label": "ca_ES - Catalan (Spain)"
    },
    {
        "value": "kw_GB",
        "label": "kw_GB - Cornish (United Kingdom)"
    },
    {
        "value": "hr_HR",
        "label": "hr_HR - Croatian (Croatia)"
    },
    {
        "value": "cs_CZ",
        "label": "cs_CZ - Czech (Czech Republic)"
    },
    {
        "value": "da_DK",
        "label": "da_DK - Danish (Denmark)"
    },
    {
        "value": "nl_BE",
        "label": "nl_BE - Dutch (Belgium)"
    },
    {
        "value": "nl_NL",
        "label": "nl_NL - Dutch (Netherlands)"
    },
    {
        "value": "en_AS",
        "label": "en_AS - English (American Samoa)"
    },
    {
        "value": "en_AU",
        "label": "en_AU - English (Australia)"
    },
    {
        "value": "en_BE",
        "label": "en_BE - English (Belgium)"
    },
    {
        "value": "en_BZ",
        "label": "en_BZ - English (Belize)"
    },
    {
        "value": "en_BW",
        "label": "en_BW - English (Botswana)"
    },
    {
        "value": "en_CA",
        "label": "en_CA - English (Canada)"
    },
    {
        "value": "en_GU",
        "label": "en_GU - English (Guam)"
    },
    {
        "value": "en_HK",
        "label": "en_HK - English (Hong Kong SAR China)"
    },
    {
        "value": "en_IN",
        "label": "en_IN - English (India)"
    },
    {
        "value": "en_IE",
        "label": "en_IE - English (Ireland)"
    },
    {
        "value": "en_IL",
        "label": "en_IL - English (Israel)"
    },
    {
        "value": "en_JM",
        "label": "en_JM - English (Jamaica)"
    },
    {
        "value": "en_MT",
        "label": "en_MT - English (Malta)"
    },
    {
        "value": "en_MH",
        "label": "en_MH - English (Marshall Islands)"
    },
    {
        "value": "en_MU",
        "label": "en_MU - English (Mauritius)"
    },
    {
        "value": "en_NA",
        "label": "en_NA - English (Namibia)"
    },
    {
        "value": "en_NZ",
        "label": "en_NZ - English (New Zealand)"
    },
    {
        "value": "en_MP",
        "label": "en_MP - English (Northern Mariana Islands)"
    },
    {
        "value": "en_PK",
        "label": "en_PK - English (Pakistan)"
    },
    {
        "value": "en_PH",
        "label": "en_PH - English (Philippines)"
    },
    {
        "value": "en_SG",
        "label": "en_SG - English (Singapore)"
    },
    {
        "value": "en_ZA",
        "label": "en_ZA - English (South Africa)"
    },
    {
        "value": "en_TT",
        "label": "en_TT - English (Trinidad and Tobago)"
    },
    {
        "value": "en_UM",
        "label": "en_UM - English (U.S. Minor Outlying Islands)"
    },
    {
        "value": "en_VI",
        "label": "en_VI - English (U.S. Virgin Islands)"
    },
    {
        "value": "en_GB",
        "label": "en_GB - English (United Kingdom)"
    },
    {
        "value": "en_US",
        "label": "en_US - English (United States)"
    },
    {
        "value": "en_ZW",
        "label": "en_ZW - English (Zimbabwe)"
    },
    {
        "value": "et_EE",
        "label": "et_EE - Estonian (Estonia)"
    },
    {
        "value": "ee_GH",
        "label": "ee_GH - Ewe (Ghana)"
    },
    {
        "value": "ee_TG",
        "label": "ee_TG - Ewe (Togo)"
    },
    {
        "value": "fo_FO",
        "label": "fo_FO - Faroese (Faroe Islands)"
    },
    {
        "value": "fi_FI",
        "label": "fi_FI - Finnish (Finland)"
    },
    {
        "value": "fr_BE",
        "label": "fr_BE - French (Belgium)"
    },
    {
        "value": "fr_BJ",
        "label": "fr_BJ - French (Benin)"
    },
    {
        "value": "fr_BF",
        "label": "fr_BF - French (Burkina Faso)"
    },
    {
        "value": "fr_BI",
        "label": "fr_BI - French (Burundi)"
    },
    {
        "value": "fr_CM",
        "label": "fr_CM - French (Cameroon)"
    },
    {
        "value": "fr_CA",
        "label": "fr_CA - French (Canada)"
    },
    {
        "value": "fr_CF",
        "label": "fr_CF - French (Central African Republic)"
    },
    {
        "value": "fr_TD",
        "label": "fr_TD - French (Chad)"
    },
    {
        "value": "fr_KM",
        "label": "fr_KM - French (Comoros)"
    },
    {
        "value": "fr_CG",
        "label": "fr_CG - French (Congo - Brazzaville)"
    },
    {
        "value": "fr_CD",
        "label": "fr_CD - French (Congo - Kinshasa)"
    },
    {
        "value": "fr_CI",
        "label": "fr_CI - French (Côte d’Ivoire)"
    },
    {
        "value": "fr_DJ",
        "label": "fr_DJ - French (Djibouti)"
    },
    {
        "value": "fr_GQ",
        "label": "fr_GQ - French (Equatorial Guinea)"
    },
    {
        "value": "fr_FR",
        "label": "fr_FR - French (France)"
    },
    {
        "value": "fr_GA",
        "label": "fr_GA - French (Gabon)"
    },
    {
        "value": "fr_GP",
        "label": "fr_GP - French (Guadeloupe)"
    },
    {
        "value": "fr_GN",
        "label": "fr_GN - French (Guinea)"
    },
    {
        "value": "fr_LU",
        "label": "fr_LU - French (Luxembourg)"
    },
    {
        "value": "fr_MG",
        "label": "fr_MG - French (Madagascar)"
    },
    {
        "value": "fr_ML",
        "label": "fr_ML - French (Mali)"
    },
    {
        "value": "fr_MQ",
        "label": "fr_MQ - French (Martinique)"
    },
    {
        "value": "fr_MC",
        "label": "fr_MC - French (Monaco)"
    },
    {
        "value": "fr_NE",
        "label": "fr_NE - French (Niger)"
    },
    {
        "value": "fr_RW",
        "label": "fr_RW - French (Rwanda)"
    },
    {
        "value": "fr_RE",
        "label": "fr_RE - French (Réunion)"
    },
    {
        "value": "fr_BL",
        "label": "fr_BL - French (Saint Barthélemy)"
    },
    {
        "value": "fr_MF",
        "label": "fr_MF - French (Saint Martin)"
    },
    {
        "value": "fr_SN",
        "label": "fr_SN - French (Senegal)"
    },
    {
        "value": "fr_CH",
        "label": "fr_CH - French (Switzerland)"
    },
    {
        "value": "fr_TG",
        "label": "fr_TG - French (Togo)"
    },
    {
        "value": "ff_SN",
        "label": "ff_SN - Fulah (Senegal)"
    },
    {
        "value": "gl_ES",
        "label": "gl_ES - Galician (Spain)"
    },
    {
        "value": "lg_UG",
        "label": "lg_UG - Ganda (Uganda)"
    },
    {
        "value": "ka_GE",
        "label": "ka_GE - Georgian (Georgia)"
    },
    {
        "value": "de_AT",
        "label": "de_AT - German (Austria)"
    },
    {
        "value": "de_BE",
        "label": "de_BE - German (Belgium)"
    },
    {
        "value": "de_DE",
        "label": "de_DE - German (Germany)"
    },
    {
        "value": "de_LI",
        "label": "de_LI - German (Liechtenstein)"
    },
    {
        "value": "de_LU",
        "label": "de_LU - German (Luxembourg)"
    },
    {
        "value": "de_CH",
        "label": "de_CH - German (Switzerland)"
    },
    {
        "value": "el_CY",
        "label": "el_CY - Greek (Cyprus)"
    },
    {
        "value": "el_GR",
        "label": "el_GR - Greek (Greece)"
    },
    {
        "value": "gu_IN",
        "label": "gu_IN - Gujarati (India)"
    },
    {
        "value": "he_IL",
        "label": "he_IL - Hebrew (Israel)"
    },
    {
        "value": "hi_IN",
        "label": "hi_IN - Hindi (India)"
    },
    {
        "value": "hu_HU",
        "label": "hu_HU - Hungarian (Hungary)"
    },
    {
        "value": "is_IS",
        "label": "is_IS - Icelandic (Iceland)"
    },
    {
        "value": "ig_NG",
        "label": "ig_NG - Igbo (Nigeria)"
    },
    {
        "value": "id_ID",
        "label": "id_ID - Indonesian (Indonesia)"
    },
    {
        "value": "ga_IE",
        "label": "ga_IE - Irish (Ireland)"
    },
    {
        "value": "it_IT",
        "label": "it_IT - Italian (Italy)"
    },
    {
        "value": "it_CH",
        "label": "it_CH - Italian (Switzerland)"
    },
    {
        "value": "ja_JP",
        "label": "ja_JP - Japanese (Japan)"
    },
    {
        "value": "kl_GL",
        "label": "kl_GL - Kalaallisut (Greenland)"
    },
    {
        "value": "kn_IN",
        "label": "kn_IN - Kannada (India)"
    },
    {
        "value": "km_KH",
        "label": "km_KH - Khmer (Cambodia)"
    },
    {
        "value": "ki_KE",
        "label": "ki_KE - Kikuyu (Kenya)"
    },
    {
        "value": "rw_RW",
        "label": "rw_RW - Kinyarwanda (Rwanda)"
    },
    {
        "value": "ko_KR",
        "label": "ko_KR - Korean (South Korea)"
    },
    {
        "value": "lv_LV",
        "label": "lv_LV - Latvian (Latvia)"
    },
    {
        "value": "lt_LT",
        "label": "lt_LT - Lithuanian (Lithuania)"
    },
    {
        "value": "mk_MK",
        "label": "mk_MK - Macedonian (Macedonia)"
    },
    {
        "value": "mg_MG",
        "label": "mg_MG - Malagasy (Madagascar)"
    },
    {
        "value": "ms_BN",
        "label": "ms_BN - Malay (Brunei)"
    },
    {
        "value": "ms_MY",
        "label": "ms_MY - Malay (Malaysia)"
    },
    {
        "value": "ml_IN",
        "label": "ml_IN - Malayalam (India)"
    },
    {
        "value": "mt_MT",
        "label": "mt_MT - Maltese (Malta)"
    },
    {
        "value": "gv_GB",
        "label": "gv_GB - Manx (United Kingdom)"
    },
    {
        "value": "mr_IN",
        "label": "mr_IN - Marathi (India)"
    },
    {
        "value": "ne_IN",
        "label": "ne_IN - Nepali (India)"
    },
    {
        "value": "ne_NP",
        "label": "ne_NP - Nepali (Nepal)"
    },
    {
        "value": "nd_ZW",
        "label": "nd_ZW - North Ndebele (Zimbabwe)"
    },
    {
        "value": "nb_NO",
        "label": "nb_NO - Norwegian Bokmål (Norway)"
    },
    {
        "value": "nn_NO",
        "label": "nn_NO - Norwegian Nynorsk (Norway)"
    },
    {
        "value": "or_IN",
        "label": "or_IN - Oriya (India)"
    },
    {
        "value": "om_ET",
        "label": "om_ET - Oromo (Ethiopia)"
    },
    {
        "value": "om_KE",
        "label": "om_KE - Oromo (Kenya)"
    },
    {
        "value": "ps_AF",
        "label": "ps_AF - Pashto (Afghanistan)"
    },
    {
        "value": "fa_AF",
        "label": "fa_AF - Persian (Afghanistan)"
    },
    {
        "value": "fa_IR",
        "label": "fa_IR - Persian (Iran)"
    },
    {
        "value": "pl_PL",
        "label": "pl_PL - Polish (Poland)"
    },
    {
        "value": "pt_BR",
        "label": "pt_BR - Portuguese (Brazil)"
    },
    {
        "value": "pt_GW",
        "label": "pt_GW - Portuguese (Guinea-Bissau)"
    },
    {
        "value": "pt_MZ",
        "label": "pt_MZ - Portuguese (Mozambique)"
    },
    {
        "value": "pt_PT",
        "label": "pt_PT - Portuguese (Portugal)"
    },
    {
        "value": "ro_MD",
        "label": "ro_MD - Romanian (Moldova)"
    },
    {
        "value": "ro_RO",
        "label": "ro_RO - Romanian (Romania)"
    },
    {
        "value": "rm_CH",
        "label": "rm_CH - Romansh (Switzerland)"
    },
    {
        "value": "ru_MD",
        "label": "ru_MD - Russian (Moldova)"
    },
    {
        "value": "ru_RU",
        "label": "ru_RU - Russian (Russia)"
    },
    {
        "value": "ru_UA",
        "label": "ru_UA - Russian (Ukraine)"
    },
    {
        "value": "sg_CF",
        "label": "sg_CF - Sango (Central African Republic)"
    },
    {
        "value": "sn_ZW",
        "label": "sn_ZW - Shona (Zimbabwe)"
    },
    {
        "value": "ii_CN",
        "label": "ii_CN - Sichuan Yi (China)"
    },
    {
        "value": "si_LK",
        "label": "si_LK - Sinhala (Sri Lanka)"
    },
    {
        "value": "sk_SK",
        "label": "sk_SK - Slovak (Slovakia)"
    },
    {
        "value": "sl_SI",
        "label": "sl_SI - Slovenian (Slovenia)"
    },
    {
        "value": "so_DJ",
        "label": "so_DJ - Somali (Djibouti)"
    },
    {
        "value": "so_ET",
        "label": "so_ET - Somali (Ethiopia)"
    },
    {
        "value": "so_KE",
        "label": "so_KE - Somali (Kenya)"
    },
    {
        "value": "so_SO",
        "label": "so_SO - Somali (Somalia)"
    },
    {
        "value": "es_AR",
        "label": "es_AR - Spanish (Argentina)"
    },
    {
        "value": "es_BO",
        "label": "es_BO - Spanish (Bolivia)"
    },
    {
        "value": "es_CL",
        "label": "es_CL - Spanish (Chile)"
    },
    {
        "value": "es_CO",
        "label": "es_CO - Spanish (Colombia)"
    },
    {
        "value": "es_CR",
        "label": "es_CR - Spanish (Costa Rica)"
    },
    {
        "value": "es_DO",
        "label": "es_DO - Spanish (Dominican Republic)"
    },
    {
        "value": "es_EC",
        "label": "es_EC - Spanish (Ecuador)"
    },
    {
        "value": "es_SV",
        "label": "es_SV - Spanish (El Salvador)"
    },
    {
        "value": "es_GQ",
        "label": "es_GQ - Spanish (Equatorial Guinea)"
    },
    {
        "value": "es_GT",
        "label": "es_GT - Spanish (Guatemala)"
    },
    {
        "value": "es_HN",
        "label": "es_HN - Spanish (Honduras)"
    },
    {
        "value": "es_MX",
        "label": "es_MX - Spanish (Mexico)"
    },
    {
        "value": "es_NI",
        "label": "es_NI - Spanish (Nicaragua)"
    },
    {
        "value": "es_PA",
        "label": "es_PA - Spanish (Panama)"
    },
    {
        "value": "es_PY",
        "label": "es_PY - Spanish (Paraguay)"
    },
    {
        "value": "es_PE",
        "label": "es_PE - Spanish (Peru)"
    },
    {
        "value": "es_PR",
        "label": "es_PR - Spanish (Puerto Rico)"
    },
    {
        "value": "es_ES",
        "label": "es_ES - Spanish (Spain)"
    },
    {
        "value": "es_US",
        "label": "es_US - Spanish (United States)"
    },
    {
        "value": "es_UY",
        "label": "es_UY - Spanish (Uruguay)"
    },
    {
        "value": "es_VE",
        "label": "es_VE - Spanish (Venezuela)"
    },
    {
        "value": "sw_KE",
        "label": "sw_KE - Swahili (Kenya)"
    },
    {
        "value": "sw_TZ",
        "label": "sw_TZ - Swahili (Tanzania)"
    },
    {
        "value": "sv_FI",
        "label": "sv_FI - Swedish (Finland)"
    },
    {
        "value": "sv_SE",
        "label": "sv_SE - Swedish (Sweden)"
    },
    {
        "value": "ta_IN",
        "label": "ta_IN - Tamil (India)"
    },
    {
        "value": "ta_LK",
        "label": "ta_LK - Tamil (Sri Lanka)"
    },
    {
        "value": "te_IN",
        "label": "te_IN - Telugu (India)"
    },
    {
        "value": "th_TH",
        "label": "th_TH - Thai (Thailand)"
    },
    {
        "value": "bo_CN",
        "label": "bo_CN - Tibetan (China)"
    },
    {
        "value": "bo_IN",
        "label": "bo_IN - Tibetan (India)"
    },
    {
        "value": "ti_ER",
        "label": "ti_ER - Tigrinya (Eritrea)"
    },
    {
        "value": "ti_ET",
        "label": "ti_ET - Tigrinya (Ethiopia)"
    },
    {
        "value": "to_TO",
        "label": "to_TO - Tonga (Tonga)"
    },
    {
        "value": "tr_TR",
        "label": "tr_TR - Turkish (Turkey)"
    },
    {
        "value": "uk_UA",
        "label": "uk_UA - Ukrainian (Ukraine)"
    },
    {
        "value": "ur_IN",
        "label": "ur_IN - Urdu (India)"
    },
    {
        "value": "ur_PK",
        "label": "ur_PK - Urdu (Pakistan)"
    },
    {
        "value": "vi_VN",
        "label": "vi_VN - Vietnamese (Vietnam)"
    },
    {
        "value": "cy_GB",
        "label": "cy_GB - Welsh (United Kingdom)"
    },
    {
        "value": "yo_NG",
        "label": "yo_NG - Yoruba (Nigeria)"
    },
    {
        "value": "zu_ZA",
        "label": "zu_ZA - Zulu (South Africa)"
    }
];