import React from "react";
import {useIntl} from "react-intl";
import {CsvDownloaderModal, ExportCsvData} from "peggirkit";
import {KeywordPosition} from "serpotrack-connector";

export type ExportToCsvOptions = {
    showDialog: boolean,
    data: ExportCsvData[]
};

type Props = {
    exportToCsvOptions: ExportToCsvOptions,
    setExportToCsvOptions: (newState: ExportToCsvOptions) => void
};

const KeywordsToCsvModal = ({exportToCsvOptions, setExportToCsvOptions}: Props) => {
    const intl = useIntl();
    return (
        <CsvDownloaderModal
            show={exportToCsvOptions.showDialog}
            setShow={(show: boolean) => setExportToCsvOptions({...exportToCsvOptions, showDialog: show})}
            fileName={`serpotrack-export-${new Date().toISOString()}`}
            columns={[
                {dataName: "keyword", displayName: "Keyword"},
                {
                    dataName: "positions",
                    displayName: "Position",
                    formatter: (positions: KeywordPosition[]): string =>
                        positions.length > 0 ? positions[positions.length - 1].rank + "" : ""
                },
                {
                    dataName: "lastUpdated",
                    displayName: "Updated on",
                    formatter: (lastUpdated: string | null): string =>
                        lastUpdated !== null ? new Date(lastUpdated).toISOString() : ""
                },
                {
                    dataName: "addedOn",
                    displayName: "Added on",
                    formatter: (dateString: string) => new Date(dateString).toISOString()
                },
            ]}
            data={exportToCsvOptions.data}
            title={intl.formatMessage({id: "exportToCsv"})}
            continueText={intl.formatMessage({id: "download"})}
            cancelText={intl.formatMessage({id: "cancel"})}
            bodyText={intl.formatMessage({id: "exportToCsvModalBodyText"})}
        />
    );
};

export default KeywordsToCsvModal;