import React from "react";
import {InformationCircleIcon} from "@heroicons/react/24/outline";
import {FormattedMessage} from "react-intl";
import {KeywordPositionDetails} from "serpotrack-connector";

type Props = {
    details: KeywordPositionDetails
};

const SerpResultFormatter = ({details}: Props) => {
    return (
        <div className={"w-full flex flex-col whitespace-normal break-word"}>
            <a
                className={"group flex flex-col w-full mb-1"}
                title={details.url}
                href={details.url}
                target={"_blank"}
                rel={"nofollow"}
            >
                <span className={"block whitespace-nowrap truncate max-w-[300px] text-[#202124] mb-2 text-xs"}>
                    {details.url}
                </span>
                <strong className={"group-hover:underline text-[#1a0dab] text-sm"}>
                    {details.title}
                </strong>
            </a>
            <p className={"w-full text-[#4d5156] text-sm"}>
                {details.snippet}
            </p>
            {
                details.featuredSnippet &&
                <span className={"w-full text-gray-500 text-sm italic flex gap-2"}>
                    <InformationCircleIcon width={15}/> <FormattedMessage id={"featuredSnippet"}/>
                </span>
            }
        </div>
    );
};

export default SerpResultFormatter;