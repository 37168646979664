import React from "react";
import {FormattedMessage} from "react-intl";

type Props = {
    from: number,
    to: number,
    total: number,
};

const PaginationSummaryFormatter = ({from, to, total}: Props) => {
    return (
        <FormattedMessage
            id="paginationSummaryFormatter"
            values={{
                from: <span className="font-medium">{from}</span>,
                to: <span className="font-medium">{to}</span>,
                total: <span className="font-medium">{total}</span>
            }}
        />
    );
};

export default PaginationSummaryFormatter;