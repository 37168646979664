import React, {useState} from "react";
import {TotpSetup} from "./Enable2FaModal";
import {InputField, Link} from "peggirkit";
import {ClipboardDocumentIcon} from "@heroicons/react/24/outline";
import {useIntl} from "react-intl";

type Props = {
    totpSetup?: TotpSetup
};

const TotpSecretInput = ({totpSetup}: Props) => {
    const intl = useIntl();
    const [showSecret, setShowSecret] = useState(false);

    if (!totpSetup) {
        return <></>;
    }

    if (!showSecret) {
        return (
            <Link
                text={intl.formatMessage({id: "revealSecret"})}
                href={"#"}
                onClick={(e) => {
                    e.preventDefault();
                    setShowSecret(true);
                }}
            />
        );
    }

    return (
        <InputField
            trailingButton={{
                type: "white",
                text: intl.formatMessage({id: "copy"}),
                icon: ClipboardDocumentIcon,
                iconOnly: true,
                disabled: false,
                onClick: () => navigator.clipboard.writeText(totpSetup.secret)
            }}
            hideLabel={true}
            type={"text"}
            id={"secret"}
            reactHookForm={{
                readOnly: true,
                defaultValue: totpSetup.secret
            }}
            displayName={intl.formatMessage({id: "secret"})}
        />
    );
};

export default TotpSecretInput;