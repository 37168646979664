import React from "react";
import {
    CalendarIcon,
    Cog6ToothIcon,
    ComputerDesktopIcon,
    DevicePhoneMobileIcon,
    GlobeAltIcon,
    LanguageIcon,
    MapPinIcon,
    PencilSquareIcon,
    PlusIcon,
    TrashIcon
} from "@heroicons/react/24/outline";
import {breadcrumbItemProject, breadcrumbItemProjects} from "../../../routing/Router";
import {useIntl} from "react-intl";
import {ProjectDateRange} from "./DayRangeSelector";
import {BreadcrumbsContent, PageHeading, PageHeadingMetaData, TextSkeleton} from "peggirkit";
import {Project, User} from "serpotrack-connector";

type Props = {
    projectId?: number,
    user: User | null,
    project: Project | null,
    dateRange: ProjectDateRange,
    setDateRange: (range: ProjectDateRange) => void
    setShowDeleteDialog: (show: boolean) => void
    setShowAddKeywordsDialog: (show: boolean) => void
    setShowEditProject: (show: boolean) => void
};

const PageHeadingProject = ({
                                user,
                                projectId,
                                project,
                                dateRange,
                                setDateRange,
                                setShowDeleteDialog,
                                setShowAddKeywordsDialog,
                                setShowEditProject
                            }: Props) => {
    const intl = useIntl();
    const breadcrumbs: BreadcrumbsContent = {
        backText: intl.formatMessage({id: "back"}),
        items: [
            breadcrumbItemProjects,
            breadcrumbItemProject(projectId ? projectId : -1, project?.name)
        ]
    };

    const isProjectLoading = project === null;
    const meta: PageHeadingMetaData[] = [
        {
            text: project?.name
                ? `${intl.formatMessage({id: "site"})}: ${project?.url}`
                : null,
            icon: GlobeAltIcon,
            skeleton: isProjectLoading,
            skeletonWidth: "w-[150px]"
        },
        {
            text: project?.device
                ? `${intl.formatMessage({id: "device"})}: ${intl.formatMessage({id: project?.device})}`
                : null,
            icon: project
                ? (project.device === "mobile" ? DevicePhoneMobileIcon : ComputerDesktopIcon)
                : ComputerDesktopIcon,
            skeleton: isProjectLoading,
            skeletonWidth: "w-[75px]"
        },
        {
            text: project?.geographicalLocation
                ? `${intl.formatMessage({id: "location"})}: ${project?.geographicalLocation}`
                : null,
            icon: MapPinIcon,
            skeleton: isProjectLoading,
            skeletonWidth: "w-[50px]"
        },
        {
            text: project?.homeLocation
                ? `${intl.formatMessage({id: "language"})}: ${project?.homeLocation}`
                : null,
            icon: LanguageIcon,
            skeleton: isProjectLoading,
            skeletonWidth: "w-[50px]"
        },
    ];

    return (
        <>
            <PageHeading
                breadcrumbsContent={breadcrumbs}
                title={project?.name || <TextSkeleton width={"max-w-[300px]"} height={"h-5"}/>}
                meta={meta}
                actions={{
                    moreButtonText: intl.formatMessage({id: "more"}),
                    disabled: isProjectLoading || user === null,
                    actions: [
                        {
                            actionType: "dropdown",
                            text: intl.formatMessage({id: "options"}),
                            primary: false,
                            iconOnly: true,
                            icon: Cog6ToothIcon,
                            options: [
                                [
                                    {
                                        text: intl.formatMessage({id: "edit"}),
                                        icon: PencilSquareIcon,
                                        onClick: () => setShowEditProject(true)
                                    }
                                ],
                                [
                                    {
                                        text: intl.formatMessage({id: "delete"}),
                                        type: "danger",
                                        icon: TrashIcon,
                                        onClick: () => setShowDeleteDialog(true)
                                    }
                                ]
                            ]
                        },
                        {
                            actionType: "button",
                            text: dateRange.displayName,
                            primary: false,
                            icon: CalendarIcon,
                            onClick: () => setDateRange({...dateRange, showPicker: true}),
                        },
                        {
                            actionType: "button",
                            text: intl.formatMessage({id: "addKeywords"}),
                            primary: true,
                            icon: PlusIcon,
                            onClick: () => setShowAddKeywordsDialog(true)
                        },
                    ]
                }}
            />
        </>
    );
};

export default PageHeadingProject;