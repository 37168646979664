import React, {useEffect, useState} from "react";
import {useIntl} from "react-intl";
import {Alert, NotificationService} from "peggirkit";
import {resendConfirmEmail, User} from "serpotrack-connector";

type Props = {
    user: User | null
};

const AccountNotConfirmedAlert = ({user}: Props) => {
    const intl = useIntl();
    const [loading, setLoading] = useState(false);
    const [resent, setResent] = useState(false);

    useEffect(() => {
        if (!loading) {
            return;
        }

        resendConfirmEmail().then(() => {
            setResent(true);
            NotificationService.notify({type: "success", text: intl.formatMessage({id: "confirmationMailResent"})});
        });
    }, [loading]);

    if (!user || user.verified) {
        return <></>;
    }

    return (
        <Alert
            className={"mt-8"}
            type={"danger"}
            title={intl.formatMessage({id: "accountNotConfirmed"})}
            description={intl.formatMessage({id: "confirmAccountNotice"})}
            actions={[
                ...(!resent ? [{
                    text: intl.formatMessage({id: "resendConfirmation"}),
                    onClick: () => setLoading(true),
                    loading: loading
                }] : [])
            ]}
        />
    );
};

export default AccountNotConfirmedAlert;