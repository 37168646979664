import React from "react";
import {useIntl} from "react-intl";
import router from "../../../routing/Router";
import {ResetPasswordConfirmation as ResetPasswordConfirmationPage} from "serpotrack-frontend-authentication";
import {AuthFormFooter} from "peggirkit";
// @ts-ignore
import logo from "../../../assets/img/icon.svg";

const ResetPasswordConfirmation = () => {
    const intl = useIntl();
    return (
        <ResetPasswordConfirmationPage
            logo={logo}
            onSignedInRedirectTo={router.projects.absolutePath()}
            footer={
                <AuthFormFooter
                    message={intl.formatMessage({id: "resetLinkExpired"})}
                    link={router.reset.absolutePath()}
                    linkText={intl.formatMessage({id: "requestNewReset"})}
                />
            }
        />
    );
};

export default ResetPasswordConfirmation;