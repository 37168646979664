import React, {useEffect, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {SetKeywordDetailsToShow, SetKeywordsOverviewLastUpdated} from "../../page/authenticated/Project";
import {AlertModal, AlertText, NotificationService} from "peggirkit";
import {deleteKeywords, Keyword} from "serpotrack-connector";

export type DeleteKeywordsProps = {
    projectId: number,
    keywords: Keyword[]
} & SetKeywordDetailsToShow & SetKeywordsOverviewLastUpdated;

type Props = {
    show: boolean,
    setShow: (show: boolean) => void
};

const DeleteKeywordsModal = ({
                                 projectId,
                                 keywords,
                                 show,
                                 setShow,
                                 setKeywordDetailsToShow,
                                 setKeywordsOverviewLastUpdated
                             }: DeleteKeywordsProps & Props) => {
    const [loading, setLoading] = useState(false);
    const intl = useIntl();

    useEffect(() => {
        if (!loading) {
            return;
        }

        deleteKeywords(projectId, keywords.map(k => k.id)).then(() => {
            setKeywordDetailsToShow(null);
            setKeywordsOverviewLastUpdated(new Date());
            NotificationService.notify({
                type: "success",
                text: intl.formatMessage({id: keywords.length === 1 ? "keywordDeleted" : "keywordsDeleted"})
            });
            setShow(false);
            setLoading(false);
        });
    }, [loading]);

    return (
        <AlertModal
            type={"danger"}
            loading={loading}
            show={show}
            onClose={() => setShow(false)}
            onContinue={() => setLoading(true)}
            title={
                keywords.length === 1
                    ? intl.formatMessage({id: "deleteKeyword"})
                    : intl.formatMessage({id: "deleteKeywords"}, {keywords: keywords.length})
            }
            cancelText={intl.formatMessage({id: "cancel"})}
            continueText={intl.formatMessage({id: "continue"})}
        >
            <AlertText>
                {
                    keywords.length === 1
                        ? <FormattedMessage id={"confirmDeleteSingleKeyword"}/>
                        : <FormattedMessage id={"confirmDeleteMultipleKeyword"} values={{keywords: keywords.length}}/>
                }
            </AlertText>
        </AlertModal>
    );
};

export default DeleteKeywordsModal;