import React, {useMemo} from "react";
import PaginationSummaryFormatter from "../../../PaginationSummaryFormatter";
import SerpResultFormatter from "./SerpResultFormatter";
import {useIntl} from "react-intl";
import {InformationCircleIcon} from "@heroicons/react/24/outline";
import {DataTable, EmptyState, TableColumn, TableData} from "peggirkit";
import {KeywordPositionDetails, serpotrackAxios} from "serpotrack-connector";

type Props = {
    baseUrl: string
};

const OutrankingSerpTable = ({baseUrl}: Props) => {
    const intl = useIntl();
    const columns: TableColumn[] = useMemo(() => ([
        {
            dataName: "rank",
            name: intl.formatMessage({id: "rank"}),
            sortable: true,
            sorted: "asc",
            secondary: false,
        },
        {
            dataName: "title",
            name: intl.formatMessage({id: "serpResult"}),
            sortable: false,
            secondary: true,
            formatter: (_: string, data: TableData) => <SerpResultFormatter details={data as KeywordPositionDetails}/>
        }
    ]), []);

    return (
        <DataTable
            withUrlPageParameter={false}
            scrollToTopOnPageChange={false}
            initialPageRequest={{
                axios: serpotrackAxios,
                pageSize: 5,
                pageNumber: 0,
                baseUrl: baseUrl
            }}
            columns={columns}
            messages={{
                next: intl.formatMessage({id: "next"}),
                previous: intl.formatMessage({id: "previous"})
            }}
            summaryFormatter={(from: number, to: number, total: number): JSX.Element | string => (
                <PaginationSummaryFormatter from={from} to={to} total={total}/>
            )}
            emptyState={
                <EmptyState
                    className={"border-t"}
                    icon={InformationCircleIcon}
                    title={intl.formatMessage({id: "noData"})}
                    description={intl.formatMessage({id: "noSerpDataOnDay"})}
                />
            }
            compactPagination
        />
    );
};

export default OutrankingSerpTable;