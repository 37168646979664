import React, {useMemo} from "react";
import {FolderPlusIcon, PlusIcon} from "@heroicons/react/24/outline";
import {useIntl} from "react-intl";
import ProjectsDataTableFormatterName from "./ProjectsDataTableFormatterName";
import router from "../../../routing/Router";
import {useNavigate} from "react-router-dom";
import PaginationSummaryFormatter from "../PaginationSummaryFormatter";
import {
    DataTable,
    DataTableColumn,
    EmptyState,
    TableButton,
    TableColumn,
    TableColumnBreakpoint,
    TableData
} from "peggirkit";
import {serpotrackAxios} from "serpotrack-connector";

const ProjectsDataTable = () => {
    const intl = useIntl();
    const navigate = useNavigate();
    const columns: (TableColumn & DataTableColumn)[] = useMemo(() => {
        return [
            {
                dataName: "name",
                name: intl.formatMessage({id: "project"}),
                sortable: true,
                secondary: false,
                formatter: (name: string, data: TableData): JSX.Element =>
                    <ProjectsDataTableFormatterName
                        name={name}
                        data={data}
                    />,
            },
            {
                dataName: "id",
                name: intl.formatMessage({id: "id"}),
                hidden: true,
                sortable: false,
            },
            {
                dataName: "url",
                name: intl.formatMessage({id: "site"}),
                sortable: true,
                secondary: true,
                showOnBreakpoint: TableColumnBreakpoint.ssm
            },
            {
                dataName: "device",
                name: intl.formatMessage({id: "device"}),
                sortable: true,
                secondary: true,
                formatter: (device: string): string => intl.formatMessage({id: device}),
                showOnBreakpoint: TableColumnBreakpoint.sm
            },
            {
                dataName: "geographicalLocation",
                name: intl.formatMessage({id: "country"}),
                sortable: true,
                secondary: true,
                showOnBreakpoint: TableColumnBreakpoint.sm
            },
            {
                dataName: "homeLocation",
                name: intl.formatMessage({id: "language"}),
                sortable: true,
                secondary: true,
                showOnBreakpoint: TableColumnBreakpoint.md
            },
            {
                dataName: "creationDate",
                name: intl.formatMessage({id: "addedOn"}),
                sortable: true,
                sorted: "desc",
                secondary: true,
                showOnBreakpoint: TableColumnBreakpoint.lg,
                formatter: (date: string): string => (new Date(date).toLocaleDateString())
            },
            {
                hideLabel: true,
                name: intl.formatMessage({id: "options"}),
                sortable: false,
                alignRight: true,
                secondary: true,
                formatter: (_, data: TableData): JSX.Element => (
                    <TableButton
                        text={intl.formatMessage({id: "view"})}
                        href={router.project.absolutePath(data.id)}
                    />),
                showOnBreakpoint: TableColumnBreakpoint.ssssm
            }
        ]
    }, []);

    return (
        <DataTable
            withUrlPageParameter={true}
            scrollToTopOnPageChange={true}
            initialPageRequest={{
                axios: serpotrackAxios,
                baseUrl: `${process.env.SERPOTRACK_API_BASE_URL}/projects`,
                pageSize: 10,
                pageNumber: 0
            }}
            columns={columns}
            onRowClick={(data: TableData) => {
                const projectUrl: string = router.project.absolutePath(data.ID);
                navigate(projectUrl);
            }}
            summaryFormatter={(from: number, to: number, total: number): JSX.Element | string => (
                <PaginationSummaryFormatter from={from} to={to} total={total}/>
            )}
            messages={{
                next: intl.formatMessage({id: "next"}),
                previous: intl.formatMessage({id: "previous"})
            }}
            emptyState={
                <EmptyState
                    className={"border-t"}
                    icon={FolderPlusIcon}
                    title={intl.formatMessage({id: "noProjects"})}
                    description={intl.formatMessage({id: "noProjectsDescriptionGetStarted"})}
                    action={{
                        text: intl.formatMessage({id: "newProject"}),
                        icon: PlusIcon,
                        onClick: () => navigate(router.newProject.absolutePath())
                    }}
                />
            }
            className={"mt-8"}
        />
    );
};

export default ProjectsDataTable;