import React from "react";
import {XMarkIcon} from "@heroicons/react/24/outline";
import {useIntl} from "react-intl";
import {SetKeywordDetailsToShow} from "../../../page/authenticated/Project";
import KeywordDetails, {KeywordDetailsDisplay} from "./KeywordDetails";
import {Card} from "peggirkit";
import {Keyword} from "serpotrack-connector";

type Props = {
    keyword: Keyword
};

const KeywordDetailsPanel = ({
                                 currentUser,
                                 keyword,
                                 setKeywordDetailsToShow,
                                 projectId,
                                 dateRange,
                                 setKeywordsOverviewLastUpdated
                             }: (KeywordDetailsDisplay & SetKeywordDetailsToShow & Props)) => {
    const intl = useIntl();

    return (
        <Card
            header={{
                title: intl.formatMessage({id: "keywordKeywordName"}, {keyword: keyword.keyword}),
                actionType: "white",
                icon: XMarkIcon,
                actionText: "Close",
                onClick: () => setKeywordDetailsToShow(null)
            }}
        >
            <KeywordDetails
                currentUser={currentUser}
                keyword={keyword}
                projectId={projectId}
                dateRange={dateRange}
                setKeywordDetailsToShow={setKeywordDetailsToShow}
                setKeywordsOverviewLastUpdated={setKeywordsOverviewLastUpdated}
            />
        </Card>
    );
};

export default KeywordDetailsPanel;