import React, {useMemo} from "react";
import {User} from "serpotrack-connector";
import {useIntl} from "react-intl";
import EnabledIndication from "./EnabledIndication";

export const toggle2FaSettingsItem = (
    user: (User | undefined),
    setShowEnable2FaModal: ((show: boolean) => void),
    setShowDisable2FaModal: ((show: boolean) => void),
) => {
    const intl = useIntl();
    const options = useMemo(() => {
        if (user === undefined) {
            return [];
        }

        if (user.mfaTotpEnabled) {
            return [{
                text: intl.formatMessage({id: "disable"}),
                onClick: () => setShowDisable2FaModal(true),
            }];
        }

        return [{
            text: intl.formatMessage({id: "enable"}),
            onClick: () => setShowEnable2FaModal(true),
        }];
    }, [user]);

    return {
        key: intl.formatMessage({id: "twoFactorAuthentication2Fa"}),
        keyTooltip: intl.formatMessage({id: "totpFullName"}),
        value: (!user
                ? undefined
                : <EnabledIndication mfaTotpEnabled={user.mfaTotpEnabled}/>
        ),
        options: options
    };
};