import React from "react";

type Props = {
    children: JSX.Element[] | JSX.Element
};

const PlanContainer = ({children}: Props) => {
    return (
        <div className="flex flex-row items-start justify-start flex-wrap gap-8 border-0 mt-6 w-full">
            {children}
        </div>
    );
};

export default PlanContainer;