import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {FormattedMessage, useIntl} from "react-intl";
import {AlertModal, Form, InputField} from "peggirkit";
import QRCode from "qrcode";
import {confirmEnableMfaTotp, MfaBackupCode, MfaTotpSecret, requestEnableMfaTotp, User} from "serpotrack-connector";
import QrCodeImage from "./QrCodeImage";
import TotpSecretInput from "./TotpSecretInput";
import BackupCodesModal from "../../backupCodes/BackupCodesModal";

type Props = {
    show: boolean,
    setShow: (show: boolean) => void,
    user: User,
    setUser: (user: User) => void
};

export type TotpSetup = {
    secret: string,
    qrCode: string,
};

type FormValues = {
    totp: string,
};

const Enable2FaModal = ({show, setShow, user, setUser}: Props) => {
    const intl = useIntl();
    const {register, getValues, handleSubmit, formState: {errors}} = useForm<FormValues>();
    const [loading, setLoading] = useState(false);
    const [formError, setFormError] = useState<string | null>(null);
    const [totpSetup, setTotpSetup] = useState<TotpSetup>();
    const [showBackupCodesModal, setShowBackupCodesModal] = useState(false);
    const [backupCodes, setBackupCodes] = useState<MfaBackupCode[]>([]);

    const displaySecret = (secret: MfaTotpSecret) => {
        QRCode.toDataURL(
            `otpauth://totp/Serpotrack:${user.username}?secret=${secret.mfaTotpSecret}&issuer=Serpotrack&algorithm=SHA1&digits=6&period=30`,
            {margin: 0, width: 150}
        ).then(url => {
            setTotpSetup({
                secret: secret.mfaTotpSecret,
                qrCode: url
            });
        }).catch(_ => setFormError(intl.formatMessage({id: "unexpectedServerError"})));
    };

    // Get new secret after opening the modal
    useEffect(() => {
        if (!show) {
            return;
        }

        requestEnableMfaTotp()
            .then(s => displaySecret(s))
            .catch(() => setFormError(intl.formatMessage({id: "unexpectedServerError"})));
    }, [show]);

    // Enable 2FA
    useEffect(() => {
        if (!loading || totpSetup === undefined) {
            return;
        }

        const values: FormValues = getValues();
        confirmEnableMfaTotp(values.totp)
            .then((e: MfaBackupCode[]) => {
                setUser({...user, mfaTotpEnabled: true,});
                setBackupCodes(e);
                setLoading(false);
            })
            .catch(_ => {
                setFormError(intl.formatMessage({id: "entered2FaCodeWrong"}));
                setLoading(false);
            });
    }, [loading]);

    // Show backup codes modal
    useEffect(() => {
        if (!totpSetup || backupCodes.length === 0) {
            return;
        }

        setShow(false);
        setShowBackupCodesModal(true);
    }, [backupCodes]);

    return (
        <>
            <AlertModal
                type="info"
                show={show}
                hideIcon={true}
                onClose={() => setShow(false)}
                onContinue={handleSubmit(() => setLoading(true))}
                title={intl.formatMessage({id: "enable2Fa"})}
                cancelText={intl.formatMessage({id: "cancel"})}
                continueText={intl.formatMessage({id: "confirmEnable"})}
                loading={loading}
            >
                <div className={"mb-6 space-y-6 text-sm"}>
                    <p>
                        <FormattedMessage id={"setup2Fa.step-1"}/>
                    </p>
                    <div className={"w-full flex flex-col items-center py-6 gap-6"}>
                        <QrCodeImage totpSetup={totpSetup}/>

                        <div className={"w-full text-center max-w-xs"}>
                            <TotpSecretInput totpSetup={totpSetup}/>
                        </div>
                    </div>
                    <p>
                        <FormattedMessage id={"setup2Fa.step-2"}/>
                    </p>

                    <Form
                        onSubmit={handleSubmit(() => setLoading(true))}
                        error={formError}
                    >
                        <InputField
                            disabled={totpSetup === undefined || loading}
                            type={"text"}
                            id={"totp"}
                            displayName={intl.formatMessage({id: "generated6DigitCode"})}
                            placeholder={intl.formatMessage({id: "6DigitCode"})}
                            tip={intl.formatMessage({id: "totpTip"})}
                            error={errors.totp && errors.totp.message}
                            reactHookForm={{
                                ...register("totp", {
                                    required: intl.formatMessage({id: "enter6DigitCode"}),
                                    pattern: {
                                        value: /^\d{6}$/,
                                        message: intl.formatMessage({id: "codeMustBe6Digits"})
                                    },
                                })
                            }}
                        />
                    </Form>
                </div>
            </AlertModal>

            <BackupCodesModal
                show={showBackupCodesModal}
                close={() => setShowBackupCodesModal(false)}
                codes={backupCodes}
            />
        </>
    );
};

export default Enable2FaModal;