import React from "react";
import {useIntl} from "react-intl";
import {Table, TextSkeleton} from "peggirkit";

const OutrankingSerpTablePlaceholder = () => {
    const intl = useIntl();
    return (
        <Table
            hideHeader={true}
            columns={[
                {
                    name: intl.formatMessage({id: "question"}),
                    sortable: false,
                    secondary: false,
                }
            ]}
            data={[...Array(2)].map(() => ({
                [intl.formatMessage({id: "question"})]:
                    <TextSkeleton
                        pulse={false}
                        width={"max-w-[600px]"}
                    />
            }))}
        />
    );
};

export default OutrankingSerpTablePlaceholder;