import React, {useEffect, useState} from "react";
import Plan, {PlanInstance} from "./Plan";
import {useIntl} from "react-intl";
import {startTrial} from "serpotrack-connector";

type Props = {
    verified: boolean
};

const PlanTrial = ({disabled, setDisabled, verified}: (PlanInstance & Props)) => {
    const intl = useIntl();
    const [isStartingTrial, setStartingTrial] = useState(false);

    useEffect(() => {
        if (!isStartingTrial) {
            return;
        }

        setDisabled(true);
        startTrial().then(() => {
            location.reload();
        });
    }, [isStartingTrial]);

    return (
        <Plan
            title={intl.formatMessage({id: "freeTrial"})}
            description={intl.formatMessage({id: "tryServiceForFree"})}
            actionText={intl.formatMessage({id: "start5DayTrial"})}
            onClick={() => setStartingTrial(true)}
            dangerAlertText={!verified ? intl.formatMessage({id: "verifyToStartTrial"}) : undefined}
            initialPlan={{
                projects: 5,
                history: -1,
                keywords: 10,
                maxPosition: 100,
                updateInterval: 48,
            }}
            composeEnabled={false}
            price={0}
            disabled={disabled}
            loading={isStartingTrial}
        />
    );
};

export default PlanTrial;