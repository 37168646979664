import React from "react";
import {useIntl} from "react-intl";
import {capitalizeFirstLetter, TableData} from "peggirkit";

type Props = {
    name: string,
    data: TableData
};

const ProjectsDataTableFormatterName = ({name, data}: Props) => {
    const intl = useIntl();
    return (
        <>
            <span className={"hidden ssm:block"}>{name}</span>
            <div className={"ssm:hidden"}>
                <div className={"flex flex-col"}>
                    <span>{name}</span>
                    <div className={"flex flex-col mt-1 items-start"}>
                        <span className={"text-gray-500 text-xs"}>
                            {capitalizeFirstLetter(data.url)}
                        </span>
                        <span className={"text-gray-500 text-xs"}>
                            {intl.formatMessage({id: data.device})}
                        </span>
                        <span className={"text-gray-500 text-xs"}>
                            {`${data.geographicalLocation} (${data.homeLocation})`}
                        </span>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ProjectsDataTableFormatterName;