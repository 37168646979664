import React from "react";
import {MfaBackupCode} from "serpotrack-connector";
import {AlertModal, AlertText} from "peggirkit";
import {FormattedMessage, useIntl} from "react-intl";

type Props = {
    show: boolean,
    close: () => void,
    codes: MfaBackupCode[]
};

const BackupCodesModal = ({show, close, codes}: Props) => {
    const intl = useIntl();
    return (
        <AlertModal
            type={"info"}
            onClose={close}
            cancelText={intl.formatMessage({id: "close"})}
            show={show}
            title={intl.formatMessage({id: "backupCodes"})}
            loading={false}
        >
            <div className={"flex flex-col gap-6 mb-6"}>
                <AlertText>
                    <FormattedMessage id={"backupCodes.description"}/>
                </AlertText>

                <ul className={"list-disc list-inside text-sm"}>
                    {codes.map((c, i) => <li key={i}>{c.code}</li>)}
                </ul>
            </div>
        </AlertModal>
    );
};

export default BackupCodesModal;