import React from "react";
import PositionHistorySection from "./history/PositionHistorySection";
import OutrankingSerpSection from "./outrankingSerp/OutrankingSerpSection";
import {ProjectDateRange} from "../DayRangeSelector";
import NoDataAlert from "./NoDataAlert";
import FooterSection from "./FooterSection";
import {SetKeywordDetailsToShow, SetKeywordsOverviewLastUpdated} from "../../../page/authenticated/Project";
import RankingUrlsSection from "./rankingUrls/RankingUrlsSection";
import StatsSection from "./stats/StatsSection";
import {Keyword, User} from "serpotrack-connector";
import PeopleAlsoAskSection from "./peopleAlsoAsk/PeopleAlsoAskSection";

export type KeywordDetailsDisplay = {
    currentUser: User | null,
    projectId: number,
    dateRange: ProjectDateRange
} & SetKeywordDetailsToShow & SetKeywordsOverviewLastUpdated;

type Props = {
    keyword: Keyword
};

const KeywordDetails = ({
                            currentUser,
                            keyword,
                            projectId,
                            dateRange,
                            setKeywordDetailsToShow,
                            setKeywordsOverviewLastUpdated
                        }: KeywordDetailsDisplay & Props) => {
    return (
        <div className={"space-y-14 pb-4 xl:pb-0"}>
            <NoDataAlert
                currentUser={currentUser}
                keyword={keyword}
            />

            <StatsSection positions={keyword.positions}/>

            <PositionHistorySection positions={keyword.positions}/>

            <RankingUrlsSection positions={keyword.positions}/>

            <PeopleAlsoAskSection
                projectId={projectId}
                keyword={keyword}
                user={currentUser}
            />

            <OutrankingSerpSection
                projectId={projectId}
                keyword={keyword}
                user={currentUser}
            />

            <FooterSection
                projectId={projectId}
                keyword={keyword}
                setKeywordDetailsToShow={setKeywordDetailsToShow}
                setKeywordsOverviewLastUpdated={setKeywordsOverviewLastUpdated}
            />
        </div>
    );
};

export default KeywordDetails;