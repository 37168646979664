import React from "react";
import {FormattedMessage} from "react-intl";

const AuthFormRegisterDisabled = () => {
    return (
        <div>
            <p>
                <FormattedMessage id={"registrationsDisabledMessage"}/>
            </p>
        </div>
    );
};

export default AuthFormRegisterDisabled;