import React, {useEffect, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import PlanContainer from "./PlanContainer";
import Plan from "./Plan";
import {AlertModal, AlertText, NotificationService, SectionHeading} from "peggirkit";
import {cancelActiveSubscription, Subscription} from "serpotrack-connector";

type Props = {
    subscription: Subscription
};

const SubscriptionTabSectionActiveSubscription = ({subscription}: Props) => {
    const intl = useIntl();
    const [activeSubscription, setActiveSubscription] = useState(subscription);
    const [showConfirmCancel, setShowConfirmCancel] = useState(false);
    const [isCancelling, setCancelling] = useState(false);

    useEffect(() => {
        if (!isCancelling) {
            return
        }

        cancelActiveSubscription().then(() => {
            setCancelling(false);
            setShowConfirmCancel(false);
            NotificationService.notify({type: "success", text: intl.formatMessage({id: "subscriptionStopped"})});
            setActiveSubscription({...activeSubscription, cancelled: true});
        });
    }, [isCancelling]);

    return (
        <>
            <SectionHeading
                title={intl.formatMessage({id: "activePlan"})}
                description={intl.formatMessage({id: "activePlanDescription"})}
            />

            <PlanContainer>
                <Plan
                    title={activeSubscription.plan.name}
                    description={intl.formatMessage({id: "currentlyActive"})}
                    onClick={() => setShowConfirmCancel(true)}
                    actionText={intl.formatMessage({id: "cancel"})}
                    actionType={"danger"}
                    initialPlan={{
                        projects: activeSubscription.plan.projectLimit,
                        keywords: activeSubscription.plan.keywordLimit,
                        history: activeSubscription.plan.historyLimit,
                        maxPosition: activeSubscription.plan.keywordMaxPositionLimit,
                        updateInterval: activeSubscription.plan.keywordUpdateHourlyIntervalLimit,
                        endDate: activeSubscription.endDate
                    }}
                    cancelled={activeSubscription.cancelled}
                    composeEnabled={false}
                    disabled={false}
                    loading={false}
                />
            </PlanContainer>

            <AlertModal
                type={"danger"}
                loading={isCancelling}
                show={showConfirmCancel}
                onClose={() => setShowConfirmCancel(false)}
                onContinue={() => setCancelling(true)}
                title={intl.formatMessage({id: "confirm"})}
                cancelText={intl.formatMessage({id: "cancel"})}
                continueText={intl.formatMessage({id: "continue"})}
            >
                <AlertText>
                    <FormattedMessage id="confirmCancelSubscription.description"/>
                </AlertText>
            </AlertModal>
        </>
    );
};

export default SubscriptionTabSectionActiveSubscription;