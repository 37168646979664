import useResizeObserver from '@react-hook/resize-observer';
import React, {cloneElement, isValidElement, useLayoutEffect, useState} from "react";

type Props = {
    children: JSX.Element | JSX.Element[]
};

const useSize = (target: HTMLElement | null): DOMRect | null => {
    const [size, setSize] = useState<DOMRect | null>(null);

    useLayoutEffect(() => {
        setSize(target?.getBoundingClientRect() ?? null);
    }, [target]);

    useResizeObserver(target, entry => {
        setSize(entry.contentRect);
    });

    return size;
}

/**
 * This component is a workaround for a Recharts / React.js 18 bug:
 * https://github.com/recharts/recharts/issues/2831
 */
const ResponsiveContainer = ({children}: Props) => {
    const [container, setContainer] = useState<HTMLDivElement | null>(null);
    const containerSize = useSize(container);

    const renderChart = () => {
        if (isValidElement(children)) {
            return cloneElement(children, {
                width: containerSize?.width ?? '',
                height: containerSize?.height ?? ''
            });
        }

        return null;
    };

    return (
        <div
            className="recharts-responsive-container"
            style={{width: "100%", height: "300px"}}
            ref={setContainer}
        >
            {renderChart()}
        </div>
    );
};

export default ResponsiveContainer;