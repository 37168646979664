import React from "react";
import {TotpSetup} from "./Enable2FaModal";
import {useIntl} from "react-intl";

type Props = {
    totpSetup?: TotpSetup
};

const QrCodeImage = ({totpSetup}: Props) => {
    const intl = useIntl();

    if (!totpSetup) {
        return (
            <div className={"rounded-sm w-[150px] h-[150px] inline-block animate-pulse bg-gray-300"}>
            </div>
        );
    }

    return (
        <img
            alt={intl.formatMessage({id: "qrCode"})}
            width={150}
            height={150}
            title={intl.formatMessage({id: "scanWithYourApp"})}
            src={totpSetup.qrCode}
        />
    );
};

export default QrCodeImage;