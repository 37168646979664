import React from "react";
import {Alert} from "peggirkit";
import {ShieldCheckIcon} from "@heroicons/react/24/outline";
import {FormattedMessage, useIntl} from "react-intl";

type Props = {
    mfaTotpEnabled: boolean
};

const EnabledIndication = ({mfaTotpEnabled}: Props) => {
    const intl = useIntl();

    if (mfaTotpEnabled) {
        return (
            <div className={"flex flex-row items-center gap-2"}>
                <ShieldCheckIcon className={"w-6 h-6 text-green-700"}/>
                <span>
                    <FormattedMessage id={"enabled"}/>
                </span>
            </div>
        );
    }

    return (
        <div className={"flex flex-col w-full"}>
            <Alert
                type={"warning"}
                title={intl.formatMessage({id: "notEnabled"})}
                description={intl.formatMessage({id: "2faNotEnabledWarning"})}
            />
        </div>
    );
};

export default EnabledIndication;